import { ChangeEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addKey } from "../config/functions"
import { FormType, FormTypeList } from "../pages/Group"
import { ActivityManagementType, ActivityStatus, addActivityManagement, editActivityManagement, StatusTypes } from "../redux/actions/activityManagement"
import { Group } from "../redux/actions/groupActions"
import { MemberType } from "../redux/actions/memberActions"
import { Key } from "../redux/actions/types"
import { AppDispatch, StoreState } from "../redux/store"
import { FormInput } from "./custom/components"
import Option from "./Option"
import { ToastTypes, useToast } from "./toast/Toastprovider"

const STATUSES = [
    {
        text: 'NEW',
        value: 'New',
    },
    {
        text: 'PROGRESS',
        value: 'Progress',
    },
    {
        text: 'CANCELLED',
        value: 'Cancelled',
    },
    {
        text: 'COMPLETED',
        value: 'Completed',
    },
]

interface Props {
    formType: FormType
    editData?: ActivityManagementType
    onSave: () => void
}

const ActivityManagementForm = ({ formType, onSave, editData }: Props) => {
    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()
    const MemberList = useSelector<StoreState, Array<MemberType & Key>>(state => addKey(state.member))
    const groupList = useSelector<StoreState, Array<Group>>(state => (state.group))

    const [groupId, setGroupId] = useState<string>('')
    const [groupIdError, setGroupIdError] = useState<string>('')
    const [groupName, setGroupName] = useState<string>('')
    const [memberId, setMemberId] = useState<string>('')
    const [memberIdError, setMemberIdError] = useState<string>('')
    const [memberName, setMemberName] = useState<string>('')
    const [startDate, setStartDate] = useState<string>('')
    const [startDateError, setStartDateError] = useState<string>('')
    const [endDate, setEndDate] = useState<string>('')
    const [endDateError, setEndDateError] = useState<string>('')
    const [hoursSpent, setHoursSpent] = useState<string>('')
    const [hoursSpentError, setHoursSpentError] = useState<string>('')
    const [task, setTask] = useState<string>('')
    const [taskError, setTaskError] = useState<string>('')
    const [status, setStatus] = useState<ActivityStatus>(StatusTypes.NEW)
    const [statusError, setStatusError] = useState<string>('')

    const resetStates = () => {
        setGroupId('')
        setGroupIdError('')
        setGroupName('')
        setMemberId('')
        setMemberIdError('')
        setMemberName('')
        setStartDate('')
        setStartDateError('')
        setEndDate('')
        setEndDateError('')
        setHoursSpent('')
        setHoursSpentError('')
        setTask('')
        setTaskError('')
        setStatus(StatusTypes.NEW)
        setStatusError('')
    }
    const handleGroupId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setGroupId(e.target.value)
        const index = groupList.findIndex(g => g.id !== undefined && g.id.toString() === e.target.value)

        if (index > -1) {
            setGroupName(groupList[index].name)
        }
        setGroupIdError('')
    }
    const handleMemberId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setMemberId(e.target.value)
        const index = MemberList.findIndex(sm => sm.id !== undefined && sm.id === e.target.value)

        if (index > -1) {
            setMemberName(MemberList[index].name)
        }
        setMemberIdError('')
    }

    const handleEndDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setEndDate(e.target.value)
        setEndDateError('')
    }

    const handleStartDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setStartDate(e.target.value)
        setStartDateError('')
    }

    const handleHoursSpent: ChangeEventHandler<HTMLInputElement> = (e) => {
        setHoursSpent(e.target.value)
        setHoursSpentError('')
    }

    const handleTask: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTask(e.target.value)
        setTaskError('')
    }

    const handleStatus: ChangeEventHandler<HTMLInputElement> = (e) => {
        setStatus(e.target.value as ActivityStatus)
        setStatusError('')
    }

    const handleClear = () => {
        resetStates()
    }

    const handleSave = () => {
        // console.log(memberId);
        // console.log(memberName);

        let error = false
        const data: ActivityManagementType = {
            memberId: memberId,
            memberName: memberName,
            groupId: groupId,
            groupName: groupName,
            hoursSpent: parseFloat(hoursSpent),
            endDate: endDate,
            startDate: startDate,
            status: status,
            task: task
        }

        if (data.memberId === '') {
            setMemberIdError('Member required')
            error = true
        }

        if (data.groupId === '') {
            setGroupIdError('Group required')
            error = true
        }

        if (data.startDate === '') {
            setStartDateError('Start Date required')
            error = true
        }
        if (data.endDate === '') {
            setEndDateError('End Date required')
            error = true
        }
        if (data.task === '') {
            setTaskError('Task required')
            error = true
        }

        if (isNaN(data.hoursSpent)) {
            setHoursSpentError('Hours Spent required')
            error = true
        } else if ((data.hoursSpent) < 0) {
            setHoursSpentError('Invalid Hours Spent')
            error = true
        }

        if ([StatusTypes.NEW, StatusTypes.COMPLETED, StatusTypes.CANCELLED, StatusTypes.PROGRESS].indexOf(data.status as StatusTypes) === -1) {
            setStatusError('Status must be NEW/PROGRESS/COMPLETED/CANCELLED')
            error = true
        }

        if (!error) {
            if (formType === FormTypeList.ADD) {

                dispatch(addActivityManagement(data)).then(text => {
                    // toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.EDIT && editData !== undefined && editData.id !== undefined) {
                dispatch(editActivityManagement(data, editData.id)).then(text => {
                    // toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }

    useEffect(() => {
        if (formType === FormTypeList.EDIT && editData !== undefined) {
            setMemberId(editData.memberId !== undefined ? editData.memberId : '')
            setMemberName(editData.memberName !== undefined ? editData.memberName : '')
            setGroupId(editData.groupId !== undefined ? editData.groupId : '')
            setGroupName(editData.groupName !== undefined ? editData.groupName : '')
            setStartDate(editData.startDate !== undefined ? editData.startDate : '')
            setEndDate(editData.endDate)
            setStatus(editData.status)
            setTask(editData.task)
            setHoursSpent(editData.hoursSpent.toString())
        } else {
            resetStates()
        }
    }, [formType, editData])


    return <div className='container'>
        <div className="row">

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Group'
                    labelClassName="required"
                    value={groupId}
                    onChange={handleGroupId}
                    placeholder='Group'
                    errorText={groupIdError}
                    containerClass="mb-2"
                    type="select"
                >
                    <option value="">Select</option>
                    {groupList.map((g, i) => {
                        return <Option value={g.id} key={i}>{g.name}</Option>
                    })}
                </FormInput>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Member'
                    labelClassName="required"
                    value={memberId}
                    onChange={handleMemberId}
                    placeholder='Member'
                    errorText={memberIdError}
                    containerClass="mb-2"
                    type="select"
                >
                    <option value=''>Select</option>
                    {MemberList.map(m => {
                        return <Option value={m.id} key={m.key}>{m.name}</Option>

                    })}
                </FormInput>
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Task'
                    labelClassName="required"
                    value={task}
                    onChange={handleTask}
                    placeholder='Task'
                    errorText={taskError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Status'
                    labelClassName="required"
                    value={status}
                    onChange={handleStatus}
                    placeholder='Status'
                    errorText={statusError}
                    containerClass="mb-2"
                    type="select"
                >
                    <option value=''>Select</option>
                    {STATUSES.map((s) => {
                        return <Option value={s.value}>{s.text}</Option>
                    })}
                </FormInput>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Hours Spent'
                    labelClassName="required"
                    value={hoursSpent}
                    onChange={handleHoursSpent}
                    placeholder='Hours Spent'
                    errorText={hoursSpentError}
                    containerClass="mb-2"
                    type="number"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Start Date'
                    labelClassName="required"
                    value={startDate}
                    onChange={handleStartDate}
                    placeholder='Start Date'
                    errorText={startDateError}
                    containerClass="mb-2"
                    type="date"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='End Date'
                    labelClassName="required"
                    value={endDate}
                    onChange={handleEndDate}
                    placeholder='End Date'
                    errorText={endDateError}
                    containerClass="mb-2"
                    type="date"
                />
            </div>
        </div>

        <div className="d-flex justify-content-end hstack gap-1 mt-2">
            <button className="btn btn-primary" onClick={handleClear}>Clear</button>
            <button className="btn btn-success" onClick={handleSave}>Save</button>
        </div>

    </div >
}
export default ActivityManagementForm