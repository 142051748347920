import { TransactionActionList, TransactionSampleData, TransactionType } from "../actions/transactionActions"
import { Action } from "../actions/types"


export const transactionReducer = (state: TransactionType[] = TransactionSampleData, action: Action) => {

    switch (action.type) {

        case TransactionActionList.ADD_TRANSACTION: {

            return [...state, action.data]
        }

        case TransactionActionList.EDIT_TRANSACTION: {

            const index = state.findIndex(i => i.id === action.id)
            const newState = [...state]

            if (index > -1) {
                newState[index] = {
                    ...action.data,
                    id: action.id
                }
            }
            return newState
        }

        case TransactionActionList.FETCH_TRANSACTION: {

            return state
        }

        case TransactionActionList.DELETE_TRANSACTION: {
            return state.filter(i => i.number !== action.data.number)

        }

        default: return state
    }

}