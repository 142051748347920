import { MouseEventHandler, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import PageTitle from "../components/custom/components/PageTitle"
import VerticalLayout from "../components/custom/layouts/Vertical"
import Modal from "../components/Modal"
import PurchaseForm from "../components/PurchaseForm"
import { deletePurchase, Purchase } from "../redux/actions/purchaseActions"
import { AppDispatch, StoreState } from "../redux/store"
import { FormType, FormTypeList } from "./Group"

interface TableBody {
    sno: number
    productId: string
    productName: string
    unit: string
    price: number
    quantity: number
    amount: number
    memberId: string
    memberName: string
    date: string

    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
}

type HandleEdit = (value: Purchase) => void
type HandleDelete = (value: Purchase) => void

const PurchaseView = () => {

    const dispatch = useDispatch<AppDispatch>()

    const purchases = useSelector<StoreState, Array<Purchase>>(state => (state.purchase))

    const [showForm, setShowForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<Purchase>()

    const handleAdd = () => {
        setShowForm(true)
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.EDIT)
        setEditData(value)
        setShowForm(true)
    }

    const handleDelete: HandleDelete = (value) => {
        if (value.id !== undefined) {
            dispatch(deletePurchase(value.id)).then(text => {
                // toast(text)
            }).catch(text => {
                // toast(text, ToastTypes.ERROR)
            })
        }
    }

    const handleFormClose = () => {
        setShowForm(false)
        setFormType(FormTypeList.ADD)
        setEditData(undefined)
    }

    return <><Modal
        headerText={formType === FormTypeList.ADD ? 'Add Purchase' : 'Edit Purchase'}
        visible={showForm}
        onClose={handleFormClose}
        size='xl'
        centered
    >
        <PurchaseForm
            formType={formType}
            editData={editData}
            onSave={handleFormClose}
        />

    </Modal>
        <VerticalLayout>
            <PageTitle
                title="Purchase"
                buttonText="Add"
                onButtonClick={handleAdd}
            />
            <div className="card">
                <div className="card-body">
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Product</th>
                                    <th className='text-truncate align-middle'>Unit</th>
                                    <th className='text-truncate align-middle'>Price</th>
                                    <th className='text-truncate align-middle'>Quantity</th>
                                    <th className='text-truncate align-middle'>Amount</th>
                                    <th className='text-truncate align-middle'>Member</th>
                                    <th className='text-truncate align-middle'>Date</th>
                                    <th className='text-truncate align-middle'>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {purchases.map((u, i) => {
                                    return <PurchaseBody
                                        sno={i + 1}
                                        productId={u.productId !== undefined ? u.productId : '0'}
                                        productName={u.productName !== undefined ? u.productName : '0'}
                                        unit={u.unit}
                                        price={u.price}
                                        quantity={u.quantity}
                                        amount={u.amount}
                                        memberId={u.memberId !== undefined ? u.memberId : '0'}
                                        memberName={u.memberName !== undefined ? u.memberName : ''}
                                        date={u.date}
                                        key={i}
                                        onEdit={() => handleEdit(u)}
                                        onDelete={() => handleDelete(u)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </VerticalLayout>
    </>
}

const PurchaseBody = ({ sno, productName, unit, price, quantity, amount, memberName, date, onEdit, onDelete }: TableBody) => {
    return <tr>
        <td className='align-middle'>{sno}</td>
        <td className='align-middle'>{productName}</td>
        <td className='text-capitalize align-middle'>{unit}</td>
        <td className='text-capitalize align-middle'>{price}</td>
        <td className='text-capitalize align-middle'>{quantity}</td>
        <td className='text-capitalize align-middle'>{amount}</td>
        <td className='text-capitalize align-middle'>{memberName}</td>
        <td className='text-capitalize align-middle'>{date}</td>
        <td>
            <div className='hstack gap-1'>
                <button className='btn btn-sm btn-primary' onClick={onEdit}>Edit</button>
                <button className='btn btn-sm btn-danger' onClick={onDelete}>Delete</button>
            </div>
        </td>
    </tr>
}

export default PurchaseView