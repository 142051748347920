import { Bar } from 'react-chartjs-2'

interface BarChartProps {
    // label: string[],
    data1: number[],
    data2: number[],
    data3: number[],
    data4: number[],
    data5: number[],
}

const BarChart = ({ data1, data2, data3, data4, data5 }: BarChartProps) => {
    return (
        <div>
            <Bar
                data={{
                    labels: ['19-12-2022', '20-12-2022', '21-12-2022', '22-12-2022', '23-12-2022', '24-12-2022'],
                    datasets: [
                        {
                            label: 'Cocunut Oil',
                            data: data1,
                            backgroundColor: '#348BFB',
                            borderColor: 'black',
                        },
                        {
                            label: 'Sesame Oil',
                            data: data2,
                            backgroundColor: '#45E393',
                            borderColor: 'black',
                        },
                        {
                            label: 'Groundnut Oil',
                            data: data4,
                            backgroundColor: '#FCAF3B',
                            borderColor: 'black',
                        },
                        {
                            label: 'Ponni Rice',
                            data: data3,
                            backgroundColor: '#FE4A65',
                            borderColor: 'black',
                        },
                        {
                            label: 'Seeraga Samba Rice',
                            data: data5,
                            backgroundColor: '#765ACB', // #5B717B
                            borderColor: 'black',
                        },
                    ],
                }}
                height={400}
                width={500}
                options={{
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                },
                            },
                        ],
                    },
                    // title: {
                    //     display: true,
                    //     text: 'Collection',
                    //     fontSize: 15
                    // },
                    legend: {
                        labels: {
                            fontSize: 20,
                        },
                        position: 'bottom'
                    },
                }}
            />
        </div>
    )
}

export default BarChart
