import { ChangeEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addKey } from "../config/functions"
import { FormType, FormTypeList } from "../pages/Group"
import { MemberType } from "../redux/actions/memberActions"
import { Product } from "../redux/actions/productActions"
import { addPurchase, editPurchase, fetchPurchase, Purchase } from "../redux/actions/purchaseActions"
import { Key } from "../redux/actions/types"
import { AppDispatch, StoreState } from "../redux/store"
import { FormInput } from "./custom/components"
import Option from "./Option"
import { ToastTypes, useToast } from "./toast/Toastprovider"


interface Props {
    formType: FormType
    editData?: Purchase
    onSave: () => void
}

const PurchaseForm = ({ formType, onSave, editData }: Props) => {
    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()
    const MemberList = useSelector<StoreState, Array<MemberType & Key>>(state => addKey(state.member))
    const products = useSelector<StoreState, Array<Product>>(state => (state.product))

    const [productId, setProductId] = useState<string>('')
    const [productIdError, setProductIdError] = useState<string>('')
    const [productName, setProductName] = useState<string>('')
    const [unit, setUnit] = useState<string>('')
    const [unitError, setUnitError] = useState<string>('')
    const [price, setPrice] = useState<string>('')
    const [priceError, setPriceError] = useState<string>('')
    const [quantity, setQuantity] = useState<string>('')
    const [quantityError, setQuantityError] = useState<string>('')
    const [amount, setAmount] = useState<string>('')
    const [amountError, setAmountError] = useState<string>('')
    const [memberId, setMemberId] = useState<string>('')
    const [memberIdError, setMemberIdError] = useState<string>('')
    const [memberName, setMemberName] = useState<string>('')
    const [date, setDate] = useState<string>('')
    const [dateError, setDateError] = useState<string>('')

    const resetStates = () => {

        setProductId('')
        setProductIdError('')
        setProductName('')
        setUnit('')
        setUnitError('')
        setPrice('')
        setPriceError('')
        setQuantity('')
        setQuantityError('')
        setAmount('')
        setAmountError('')
        setMemberId('')
        setMemberIdError('')
        setMemberName('')
        setDate('')
        setDateError('')

    }

    const handleProductId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setProductId(e.target.value)
        const index = products.findIndex(g => g.id !== undefined && g.id.toString() === e.target.value)

        if (index > -1) {
            setProductName(products[index].name)
            setUnit(products[index].unit)
            setPrice((products[index].pricePerUnit).toString())

        }
        setProductIdError('')
        setPriceError('')
        setUnitError('')
    }

    const handleUnit: ChangeEventHandler<HTMLInputElement> = (e) => {
        setUnit(e.target.value)
        setUnitError('')
    }

    const handlePrice: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPrice(e.target.value)
        setPriceError('')
    }


    const handleAmount: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAmount(e.target.value)
        setAmountError('')
    }

    const handleQuantity: ChangeEventHandler<HTMLInputElement> = (e) => {
        setQuantity(e.target.value)
        setQuantityError('')
        setAmountError('')
    }


    const handleMemberId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setMemberId(e.target.value)
        const index = MemberList.findIndex(sm => sm.id !== undefined && sm.id === e.target.value)

        if (index > -1) {
            setMemberName(MemberList[index].name)
        }
        setMemberIdError('')
    }


    const handleDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDate(e.target.value)
        setDateError('')
    }

    const handleClear = () => {
        resetStates()
    }

    const handleSave = () => {
        let error = false
        const data: Purchase = {

            productId: productId,
            productName: productName,
            unit: unit,
            price: parseInt(price),
            quantity: parseInt(quantity),
            amount: parseInt(amount),
            memberId: memberId,
            memberName: memberName,
            date: date,
        }

        if (data.productId === "") {
            setProductIdError('Product required')
            error = true
        }

        if (data.productId === "") {
            setProductIdError('Product required')
            error = true
        }

        if (data.unit === '') {
            setUnitError('Unit required')
            error = true
        } else if ((data.unit).length > 15) {
            setUnitError('Invalid unit')
            error = true
        }

        if (isNaN(data.price)) {
            setPriceError('Price required')
            error = true
        } else if (data.price <= 0) {
            setPriceError('Invalid Price')
            error = true
        }

        if (isNaN(data.quantity)) {
            setQuantityError('Quantity required')
            error = true
        } else if (data.quantity <= 0) {
            setQuantityError('Invalid Quantity')
            error = true
        }

        if (isNaN(data.amount)) {
            setAmountError('Amount required')
            error = true
        } else if ((data.amount) < 0) {
            setAmountError('Invalid Amount')
            error = true
        }

        if (data.memberId === "") {
            setMemberIdError('Member required')
            error = true
        }

        if (data.memberId === "") {
            setMemberIdError('Member required')
            error = true
        }

        if (data.date === "") {
            setDateError('Date required')
            error = true
        }

        if (!error) {
            if (formType === FormTypeList.ADD) {

                dispatch(addPurchase(data)).then(text => {
                    // toast(text)
                    onSave()
                    dispatch(fetchPurchase())
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.EDIT && editData !== undefined && editData.id !== undefined) {
                dispatch(editPurchase(data, editData.id)).then(text => {
                    // toast(text)
                    onSave()
                    dispatch(fetchPurchase())
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }

    useEffect(() => {
        if (formType === FormTypeList.EDIT && editData !== undefined) {

            setProductId(editData.productId !== undefined ? editData.productId : '')
            setProductName(editData.productName !== undefined ? editData.productName : '')
            setUnit(editData.unit)
            setPrice(editData.price.toString())
            setQuantity(editData.quantity.toString())
            setAmount(editData.amount.toString())
            setMemberId(editData.memberId !== undefined ? editData.memberId : '')
            setMemberName(editData.memberName !== undefined ? editData.memberName : '')
            setDate(editData.date)

        } else {
            resetStates()
        }
    }, [formType, editData])

    useEffect(() => {
        if (price !== "" && quantity !== "") {
            let totalAmount = (parseInt(price) * parseInt(quantity))
            setAmount(totalAmount.toString())
        }
    }, [price, quantity])


    return <div className='container'>
        <div className="row">

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Product'
                    labelClassName="required"
                    value={productId}
                    onChange={handleProductId}
                    placeholder='Product'
                    errorText={productIdError}
                    containerClass="mb-2"
                    type="select"
                >
                    <option value="">Select</option>
                    {products.map((p, i) => {
                        return <Option value={p.id} key={i}>{p.name}</Option>
                    })}
                </FormInput>
            </div>


            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Unit'
                    labelClassName="required"
                    value={unit}
                    onChange={handleUnit}
                    placeholder='Unit'
                    errorText={unitError}
                    containerClass="mb-2"
                    disabled
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Price'
                    labelClassName="required"
                    value={price}
                    onChange={handlePrice}
                    placeholder='Price'
                    errorText={priceError}
                    containerClass="mb-2"
                    type="number"
                    disabled
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Quantity'
                    labelClassName="required"
                    value={quantity}
                    onChange={handleQuantity}
                    placeholder='Quantity'
                    errorText={quantityError}
                    containerClass="mb-2"
                    type="number"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Amount'
                    labelClassName="required"
                    value={amount}
                    onChange={handleAmount}
                    placeholder='Amount'
                    errorText={amountError}
                    containerClass="mb-2"
                    type="number"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Member'
                    labelClassName="required"
                    value={memberId}
                    onChange={handleMemberId}
                    placeholder='Member'
                    errorText={memberIdError}
                    containerClass="mb-2"
                    type="select"
                >
                    <option value=''>Select</option>
                    {MemberList.map(m => {
                        return <Option value={m.id} key={m.key}>{m.name}</Option>

                    })}
                </FormInput>
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Date'
                    labelClassName="required"
                    value={date}
                    onChange={handleDate}
                    placeholder='Date'
                    errorText={dateError}
                    containerClass="mb-2"
                    type="date"
                />
            </div>

        </div>
        <div className="d-flex justify-content-end hstack gap-1">
            <button className="btn btn-primary" onClick={handleClear}>Clear</button>
            <button className="btn btn-success" onClick={handleSave}>Save</button>
        </div>

    </div>
}

export default PurchaseForm