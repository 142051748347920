import { MouseEventHandler, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import ActivityManagementForm from "../components/ActivityManagementForm"
import PageTitle from "../components/custom/components/PageTitle"
import VerticalLayout from "../components/custom/layouts/Vertical"
import Modal from "../components/Modal"
import { ActivityManagementType, ActivityStatus, deleteActivityManagement } from "../redux/actions/activityManagement"
import { AppDispatch, StoreState } from "../redux/store"

export enum FormTypeList {
    ADD = 'ADD',
    EDIT = 'EDIT'
}

export type FormType = FormTypeList.ADD | FormTypeList.EDIT;

interface TableBody {
    sno: number
    groupId?: string
    groupName?: string
    memberId?: string
    memberName?: string
    task: string
    status: ActivityStatus
    hoursSpent: number
    startDate: string
    endDate: string
    createTimestamp?: string
    createBy?: string
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
}

type HandleEdit = (value: ActivityManagementType) => void
type HandleDelete = (value: ActivityManagementType) => void

const ActivityManagement = () => {

    const dispatch = useDispatch<AppDispatch>()

    const ActivityManagementList = useSelector<StoreState, Array<ActivityManagementType>>(state => (state.activityManagement))

    const [showForm, setShowForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<ActivityManagementType>()

    const handleAdd = () => {
        setShowForm(true)
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.EDIT)
        setEditData(value)
        setShowForm(true)
    }

    const handleDelete: HandleDelete = (value) => {
        if (value.id !== undefined) {
            dispatch(deleteActivityManagement(value.id)).then(text => {
                // toast(text)
            }).catch(text => {
                // toast(text, ToastTypes.ERROR)
            })
        }
    }

    const handleFormClose = () => {
        setShowForm(false)
        setFormType(FormTypeList.ADD)
        setEditData(undefined)
    }

    return <><Modal
        headerText={formType === FormTypeList.ADD ? 'Add Activity Management' : 'Edit Activity Management'}
        visible={showForm}
        onClose={handleFormClose}
        size='xl'
        centered
    >
        <ActivityManagementForm
            formType={formType}
            editData={editData}
            onSave={handleFormClose}
        />

    </Modal>
        <VerticalLayout>
            <PageTitle
                title="ActivityManagement"
                buttonText="Add"
                onButtonClick={handleAdd}
            />

            <div className="card">
                <div className="card-body">
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Group</th>
                                    <th className='text-truncate align-middle'>Member</th>
                                    <th className='text-truncate align-middle'>Task</th>
                                    <th className='text-truncate align-middle'>Status</th>
                                    <th className='text-truncate align-middle'>Hours Spent</th>
                                    <th className='text-truncate align-middle'>Start Date</th>
                                    <th className='text-truncate align-middle'>End Date</th>
                                    <th className='text-truncate align-middle'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ActivityManagementList.map((sm, i) => {
                                    return <ActivityManagementBody
                                        sno={i + 1}
                                        memberName={sm.memberName !== undefined ? sm.memberName : ''}
                                        groupName={sm.groupName !== undefined ? sm.groupName : ''}
                                        task={sm.task}
                                        hoursSpent={sm.hoursSpent}
                                        status={sm.status}
                                        startDate={sm.startDate}
                                        endDate={sm.endDate}
                                        key={i}
                                        onEdit={() => handleEdit(sm)}
                                        onDelete={() => handleDelete(sm)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </VerticalLayout>
    </>
}

const ActivityManagementBody = ({ sno, memberName, groupName, task, status, startDate, endDate, hoursSpent, onEdit, onDelete }: TableBody) => {
    return <tr>
        <td className='align-middle'>{sno}</td>
        <td className='text-capitalize align-middle'>{groupName}</td>
        <td className='text-capitalize align-middle'>{memberName}</td>
        <td className='align-middle'>{task}</td>
        <td className='text-capitalize align-middle'>{status}</td>
        <td className='align-middle'>{hoursSpent}</td>
        <td className='align-middle'>{startDate}</td>
        <td className='align-middle'>{endDate}</td>
        <td>
            <div className='hstack gap-1'>
                <button className='btn btn-sm btn-primary' onClick={onEdit}>Edit</button>
                <button className='btn btn-sm btn-danger' onClick={onDelete}>Delete</button>
            </div>
        </td>
    </tr>
}

export default ActivityManagement