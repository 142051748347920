import { Sale, SaleActionsList, SaleSampleData } from "../actions/salesActions";
import { Action } from "../actions/types";


export const SaleReducer = (state: Sale[] = SaleSampleData, action: Action) => {

    switch (action.type) {

        case SaleActionsList.ADD_SALE: {

            return [...state, action.data]
        }

        case SaleActionsList.EDIT_SALE: {

            const index = state.findIndex(i => i.id === action.id)
            const newState = [...state]

            if (index > -1) {
                newState[index] = {
                    ...action.data,
                    id: action.id
                }
            }
            return newState
        }

        case SaleActionsList.FETCH_SALE: {

            return state
        }

        case SaleActionsList.DELETE_SALE: {
            return state.filter(i => i.id !== action.data.id)

        }

        default: return state
    }

}