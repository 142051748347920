import { Purchase, PurchaseActionsList, PurchaseSampleData } from "../actions/purchaseActions";
import { Action } from "../actions/types";


export const purchaseReducer = (state: Purchase[] = PurchaseSampleData, action: Action) => {

    switch (action.type) {

        case PurchaseActionsList.ADD_PURCHASE: {

            return [...state, action.data]
        }

        case PurchaseActionsList.EDIT_PURCHASE: {

            const index = state.findIndex(i => i.id === action.id)
            const newState = [...state]

            if (index > -1) {
                newState[index] = {
                    ...action.data,
                    id: action.id
                }
            }
            return newState
        }

        case PurchaseActionsList.FETCH_PURCHASE: {

            return state
        }

        case PurchaseActionsList.DELETE_PURCHASE: {
            return state.filter(i => i.id !== action.data.id)

        }

        default: return state
    }

}