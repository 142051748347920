import { ChangeEventHandler, MouseEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addKey } from "../config/functions"
import { FormType, FormTypeList } from "../pages/Group"
import { addExpense, ExpenseType, updateExpense } from "../redux/actions/expenseActions"
import { Key } from "../redux/actions/types"
import { AppDispatch, StoreState } from "../redux/store"
import { FormInput } from "./custom/components"
import { ToastTypes, useToast } from "./toast/Toastprovider"

interface Props {
    formType: FormType
    editData?: ExpenseType
    onSave: () => void
}
// id:PK,
// title:FT/UK,

const ExpenseForm = ({ formType, onSave, editData }: Props) => {
    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()
    const expenseList = useSelector<StoreState, Array<ExpenseType & Key>>(state => addKey(state.expense))

    const [title, setTitle] = useState<string>('')
    const [titleError, setTitleError] = useState<string>('')


    const resetStates = () => {
        setTitle('')
        setTitleError('')
    }

    const handleTitle: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTitle(e.target.value)
        setTitleError('')
    }

    const handleClear: MouseEventHandler<HTMLButtonElement> = (e) => {
        resetStates()
    }

    const handleSave = () => {
        let error = false
        const data: ExpenseType = {
            title: title,
        }
        if (formType === FormTypeList.ADD) {
            if (data.title === "") {
                setTitleError('Title required')
                error = true
            } else if ((data.title).length > 50) {
                setTitleError('Title must be within 50 characters')
                error = true
            } else if (data.title !== "") {
                const uniqueIndex = expenseList.findIndex(em => {
                    return em.title.toLowerCase() === data.title.toLowerCase()
                })
                if (uniqueIndex > -1) {
                    setTitleError('Title already exists')
                    error = true
                }
            }
        }

        if (formType === FormTypeList.EDIT) {
            if (data.title === "") {
                setTitleError('Title required')
                error = true
            } else if ((data.title).length > 50) {
                setTitleError('Title must be within 50 characters')
                error = true
            }
        }


        if (!error) {
            if (formType === FormTypeList.ADD) {

                dispatch(addExpense(data)).then(text => {
                    // toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.EDIT && editData !== undefined && editData.id !== undefined) {
                dispatch(updateExpense(data, editData.id)).then(text => {
                    // toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }

    useEffect(() => {
        if (formType === FormTypeList.EDIT && editData !== undefined) {

            setTitle(editData.title)

        } else {
            resetStates()
        }
    }, [formType, editData])


    return <div className='container'>
        <div className="row my-2">

            <div className='col-sm-12 col-md-12 col-lg-12'>
                <FormInput
                    label='Title'
                    labelClassName="required"
                    value={title}
                    onChange={handleTitle}
                    placeholder='Title'
                    errorText={titleError}
                    containerClass="mb-2"
                />
            </div>

        </div>
        <div className="d-flex justify-content-end hstack gap-1">
            <button className="btn btn-primary" onClick={handleClear}>Clear</button>
            <button className="btn btn-success" onClick={handleSave}>Save</button>
        </div>

    </div>
}
export default ExpenseForm