import { MouseEventHandler, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, StoreState } from "../redux/store";
import Modal from "../components/Modal";
import VerticalLayout from "../components/custom/layouts/Vertical";
import PageTitle from "../components/custom/components/PageTitle";
import { Sale } from "../redux/actions/salesActions";
import SaleForm from "../components/SalesForm";
import { deleteSale } from "../redux/actions/salesActions";
import { FormType, FormTypeList } from "./Group"
import { UnitTypes } from "../redux/actions/productActions"

interface TableBody {
    sno: number
    productId?: string
    price: number
    unit: UnitTypes
    amount: number
    quantity: number
    customerName: string
    customerAddress: string
    contactNumber: string
    date: string
    productName?: string
    createTimestamp?: string
    createdBy?: string
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
}

type HandleEdit = (value: Sale) => void
type HandleDelete = (value: Sale) => void

const Sales = () => {
    const dispatch = useDispatch<AppDispatch>()
    const sales = useSelector<StoreState, Array<Sale>>(state => (state.sale))

    const [showForm, setShowForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<Sale>()

    const handleAdd = () => {
        setShowForm(true)
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.EDIT)
        setEditData(value)
        setShowForm(true)
    }

    const handleDelete: HandleDelete = (value) => {
        if (value.id !== undefined) {
            dispatch(deleteSale(value.id)).then(text => {

            }).catch(text => {

            })
        }
    }

    const handleFormClose = () => {
        setShowForm(false)
        setFormType(FormTypeList.ADD)
        setEditData(undefined)
    }
    return <>
        <Modal
            headerText={formType === FormTypeList.ADD ? 'Add Sale' : 'Edit Sale'}
            visible={showForm}
            onClose={handleFormClose}
            size='xl'
            centered
        >
            <SaleForm
                formType={formType}
                editData={editData}
                onSave={handleFormClose}
            />

        </Modal>
        <VerticalLayout>
            <PageTitle
                title="Sale"
                buttonText="Add"
                onButtonClick={handleAdd}
            />

            <div className="card">
                <div className="card-body">
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Product</th>
                                    <th className='text-truncate align-middle'>Price</th>
                                    <th className='text-truncate align-middle'>Unit</th>
                                    <th className='text-truncate align-middle'>Quantity</th>
                                    <th className='text-truncate align-middle'>Amount</th>
                                    <th className='text-truncate align-middle'>Customer Name</th>
                                    <th className='text-truncate align-middle'>Customer Address</th>
                                    <th className='text-truncate align-middle'>Contact Number</th>
                                    <th className='text-truncate align-middle'>Date</th>
                                    <th className='text-truncate align-middle'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sales.map((u, i) => {
                                    return <SaleBody
                                        sno={i + 1}
                                        productId={u.productId}
                                        price={u.price}
                                        unit={u.unit}
                                        quantity={u.quantity}
                                        amount={u.amount}
                                        customerName={u.customerName}
                                        customerAddress={u.customerAddress}
                                        contactNumber={u.contactNumber}
                                        date={u.date}
                                        productName={u.productName}
                                        key={i}
                                        onEdit={() => handleEdit(u)}
                                        onDelete={() => handleDelete(u)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </VerticalLayout>
    </>
}
const SaleBody = ({ sno, productId, productName, price, unit, amount, quantity, customerName, customerAddress, contactNumber, date, onEdit, onDelete }: TableBody) => {
    return (
        <tr>
            <td className='align-middle'>{sno}</td>
            <td className='align-middle'>{productName}</td>
            <td className='align-middle'>{price}</td>
            <td className='align-middle'>{unit}</td>
            <td className='align-middle'>{quantity}</td>
            <td className='align-middle'>{amount}</td>
            <td className='align-middle'>{customerName}</td>
            <td className='align-middle'>{customerAddress}</td>
            <td className='align-middle'>{contactNumber}</td>
            <td className='align-middle'>{date}</td>

            <td>
                <div className='hstack gap-1'>
                    <button className='btn btn-sm btn-primary' onClick={onEdit}>Edit</button>
                    <button className='btn btn-sm btn-danger' onClick={onDelete}>Delete</button>
                </div>
            </td>
        </tr>
    );
}
export default Sales;