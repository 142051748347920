import { Line } from 'react-chartjs-2'

// defaults.global.tooltips.enabled = false
// defaults.global.legend.position = 'bottom'

const LineChart = () => {
    return (
        <div>
            <Line
                data={{
                    labels: ['05-12-2022', '06-12-2022', '07-12-2022', '08-12-2022', '09-12-2022'],
                    datasets: [
                        {
                            label: 'Combine Harvester',
                            data: [400, 220, 300, 580, 0],
                            fill: false,
                            borderColor: '#348BFB',
                        },
                        {
                            label: 'Rotavator',
                            data: [580, 350, 500, 400, 520],
                            fill: false,
                            borderColor: '#45E393',
                        },
                        {
                            label: 'Cultivator',
                            data: [400, 420, 200, 580, 250],
                            fill: false,
                            borderColor: '#FCAF3B',
                        },
                        {
                            label: 'Seedling',
                            data: [500, 200, 500, 200, 100],
                            fill: false,
                            borderColor: '#FE4A65',
                        },
                        {
                            label: 'Weeding',
                            data: [400, 300, 250, 300, 200],
                            fill: false,
                            backgroundColor: 'white', // #5B717B
                            borderColor: '#765ACB',
                        },
                    ],
                }}
                height={400}
                width={500}
                options={{
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                },
                            },
                        ],
                    },
                    // title: {
                    //     display: true,
                    //     text: 'Collection',
                    //     fontSize: 15
                    // },
                    legend: {
                        labels: {
                            fontSize: 20,
                        },
                        position: 'bottom'
                    },
                }}
            />
        </div>
    )
}

export default LineChart
