import { nanoid } from "@reduxjs/toolkit"
import { Dispatch } from "redux"

export interface ShareManagementType {
    id?: string
    memberId?: string
    memberName?: string
    amount: number
    date: string
    particulars: string
    createTimestamp?: string
    createBy?: string
}

export const ShareManagementSampleData = [
    {
        id: '1',
        memberId: '1',
        memberName: 'Aishu',
        amount: 200,
        date: '2022-12-13',
        particulars: 'share 1',
        createTimestamp: '1670461666',
        createBy: 'admin',
    },
    {
        id: '2',
        memberId: '2',
        memberName: 'Ram',
        amount: 350,
        date: '2022-12-13',
        particulars: 'share 2',
        createTimestamp: '1670375266',
        createBy: 'admin',
    },
]

export enum ShareManagementActionsList {
    ADD_SHARE_MANAGEMENT = 'ADD_SHARE_MANAGEMENT',
    EDIT_SHARE_MANAGEMENT = 'EDIT_SHARE_MANAGEMENT',
    FETCH_SHARE_MANAGEMENT = 'FETCH_SHARE_MANAGEMENT',
    DELETE_SHARE_MANAGEMENT = 'DELETE_SHARE_MANAGEMENT'
}

export interface DeleteShareManagement {
    id: string
}

export interface AddShareManagementAction {
    type: ShareManagementActionsList.ADD_SHARE_MANAGEMENT
    data: ShareManagementType
}

export interface EditShareManagementAction {
    type: ShareManagementActionsList.EDIT_SHARE_MANAGEMENT
    data: ShareManagementType
    id: string
}

export interface FetchShareManagementAction {
    type: ShareManagementActionsList.FETCH_SHARE_MANAGEMENT
    // data: ShareManagement[]
}

export interface DeleteShareManagementAction {
    type: ShareManagementActionsList.DELETE_SHARE_MANAGEMENT
    data: DeleteShareManagement
}

export type ShareManagementActions = AddShareManagementAction | EditShareManagementAction | FetchShareManagementAction | DeleteShareManagementAction

export const addShareManagement = (data: ShareManagementType) => {
    return async (dispatch: Dispatch) => {
        return dispatch<AddShareManagementAction>({
            type: ShareManagementActionsList.ADD_SHARE_MANAGEMENT,
            data: {
                ...data,
                id: nanoid(5)
            }
        })
    }
}

export const editShareManagement = (data: ShareManagementType, id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<EditShareManagementAction>({
            type: ShareManagementActionsList.EDIT_SHARE_MANAGEMENT,
            data: data,
            id: id
        })
    }
}

export const fetchShareManagement = () => {
    return async (dispatch: Dispatch) => {
        return dispatch<FetchShareManagementAction>({
            type: ShareManagementActionsList.FETCH_SHARE_MANAGEMENT
            // data: response.data
        })
    }
}

export const deleteShareManagement = (id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<DeleteShareManagementAction>({
            type: ShareManagementActionsList.DELETE_SHARE_MANAGEMENT,
            data: { id }
        })
    }
}