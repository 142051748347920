import { ActivityManagementActionsList, ActivityManagementSampleData, ActivityManagementType } from "../actions/activityManagement";
import { Action } from "../actions/types";


export const activityManagementReducer = (state: ActivityManagementType[] = ActivityManagementSampleData, action: Action) => {

    switch (action.type) {

        case ActivityManagementActionsList.ADD_ACTIVITY_MANAGEMENT: {

            return [...state, action.data]
        }

        case ActivityManagementActionsList.EDIT_ACTIVITY_MANAGEMENT: {

            const index = state.findIndex(i => i.id === action.id)
            const newState = [...state]

            if (index > -1) {
                newState[index] = {
                    ...action.data,
                    id: action.id
                }
            }
            return newState
        }

        case ActivityManagementActionsList.FETCH_ACTIVITY_MANAGEMENT: {

            return state
        }

        case ActivityManagementActionsList.DELETE_ACTIVITY_MANAGEMENT: {
            return state.filter(i => i.id !== action.data.id)

        }

        default: return state
    }

}