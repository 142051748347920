import { ChangeEventHandler, MouseEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FormType, FormTypeList } from "../pages/Group"
import { BillingTypes, BillingTypesList, ServiceType } from "../redux/actions/serviceActions"
import { addServiceBooking, BookingType, editServiceBooking } from "../redux/actions/serviceBookingActions"
import { AppDispatch, StoreState } from "../redux/store"
import { FormInput } from "./custom/components"
import Option from "./Option"
import { BILLING_TYPES } from "./ServiceForm"
import { ToastTypes, useToast } from "./toast/Toastprovider"

interface Props {
    formType: FormType
    editData?: BookingType
    onSave: () => void
}

//    serviceId?: string
//     serviceName?: string
//     customerName: string
//     customerAddress: string
//     contactNumber: string
//     billing: string
//     rate: number
//     unit: number
//     amount: number
//     discount: number
//     finalAmount: number
//     date: string
//     createTimestamp?: string
//     createBy?: string
// }
const ServiceBookingForm = ({ formType, onSave, editData }: Props) => {

    const toast = useToast()
    const services = useSelector<StoreState, Array<ServiceType>>(state => (state.service))

    const dispatch = useDispatch<AppDispatch>()

    const [serviceId, setServiceId] = useState<string>('')
    const [serviceIdError, setServiceIdError] = useState<string>('')
    const [serviceName, setServiceName] = useState<string>('')
    const [customerName, setCustomerName] = useState<string>('')
    const [customerNameError, setCustomerNameError] = useState<string>('')
    const [customerAddress, setCustomerAddress] = useState<string>('')
    const [customerAddressError, setCustomerAddressError] = useState<string>('')
    const [contactNumber, setContactNumber] = useState<string>('')
    const [contactNumberError, setContactNumberError] = useState<string>('')
    const [billing, setBilling] = useState<BillingTypes>(BillingTypesList.HOURLY)
    const [billingError, setBillingError] = useState<string>('')
    const [rate, setRate] = useState<string>('')
    const [rateError, setRateError] = useState<string>('')
    const [unit, setUnit] = useState<string>('')
    const [unitError, setUnitError] = useState<string>('')
    const [amount, setAmount] = useState<string>('')
    const [amountError, setAmountError] = useState<string>('')
    const [discount, setDiscount] = useState<string>('')
    const [discountError, setDiscountError] = useState<string>('')
    const [finalAmount, setFinalAmount] = useState<string>('')
    const [finalAmountError, setFinalAmountError] = useState<string>('')
    const [date, setDate] = useState<string>('')
    const [dateError, setDateError] = useState<string>('')

    const resetStates = () => {
        setCustomerName('')
        setCustomerNameError('')
        setDate('')
        setDateError('')
        setCustomerAddress('')
        setCustomerAddressError('')
        setServiceId('')
        setServiceIdError('')
        setServiceName('')
        setContactNumber('')
        setContactNumberError('')
        setRate('')
        setRateError('')
        setBilling(BillingTypesList.HOURLY)
        setBillingError('')
        setUnit('')
        setUnitError('')
        setAmount('')
        setAmountError('')
        setDiscount('')
        setDiscountError('')
        setFinalAmount('')
        setFinalAmountError('')
    }

    const handleCustomerName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCustomerName(e.target.value)
        setCustomerNameError('')
    }

    const handleCustomerAddress: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCustomerAddress(e.target.value)
        setCustomerAddressError('')
    }

    const handleContactNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setContactNumber(e.target.value)
        setContactNumberError('')
    }
    const handleDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDate(e.target.value)
        setDateError('')
    }

    const handleRate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRate(e.target.value)
        setRateError('')
    }

    const handleBilling: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBilling(e.target.value as BillingTypes)
        setBillingError('')
    }

    const handleUnit: ChangeEventHandler<HTMLInputElement> = (e) => {
        setUnit(e.target.value)
        setUnitError('')
    }

    const handleAmount: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAmount(e.target.value)
        setAmountError('')
    }

    const handleDiscount: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDiscount(e.target.value)
        setDiscountError('')
    }

    const handleFinalAmount: ChangeEventHandler<HTMLInputElement> = (e) => {
        setFinalAmount(e.target.value)
        setFinalAmountError('')
    }

    const handleServiceId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setServiceId(e.target.value)
        const index = services.findIndex(g => g.id !== undefined && g.id.toString() === e.target.value)

        if (index > -1) {
            setServiceName(services[index].title)
        }
        setServiceIdError('')
    }

    const handleClear: MouseEventHandler<HTMLButtonElement> = (e) => {
        resetStates()
    }

    const handleSave = () => {
        let error = false
        const data: BookingType = {
            serviceId: serviceId,
            serviceName: serviceName,
            date: date,
            billing: billing,
            rate: parseInt(rate),
            unit: parseInt(unit),
            amount: parseInt(amount),
            discount: parseInt(discount),
            finalAmount: parseInt(finalAmount),
            customerName: customerName,
            contactNumber: contactNumber,
            customerAddress: customerAddress
        }

        if (data.date === "") {
            setDateError('Date required')
            error = true
        }

        if ([BillingTypesList.DAILY, BillingTypesList.HOURLY].indexOf(data.billing as BillingTypes) === -1) {
            setBillingError('Billing Type must be Agri HOURLY/DAILY')
            error = true
        }

        if (data.serviceId === '') {
            setServiceIdError('Service required')
            error = true
        }//need to be changed

        if (isNaN(data.rate)) {
            setRateError('Rate required')
            error = true
        } else if (data.rate <= 0) {
            setRateError('Invalid Rate')
            error = true
        }

        if (isNaN(data.unit)) {
            setUnitError('Unit required')
            error = true
        } else if (data.unit <= 0) {
            setUnitError('Invalid Unit')
            error = true
        }

        if (isNaN(data.amount)) {
            setAmountError('Amount required')
            error = true
        } else if (data.amount <= 0) {
            setAmountError('Invalid Amount')
            error = true
        }

        if (isNaN(data.discount)) {
            setDiscountError('Discount required')
            error = true
        } else if (data.discount < 0) {
            setDiscountError('Invalid Discount')
            error = true
        }

        if (isNaN(data.finalAmount)) {
            setFinalAmountError('Final Amount required')
            error = true
        } else if (data.finalAmount <= 0) {
            setFinalAmountError('Invalid Final Amount')
            error = true
        }

        if (data.customerName === "") {
            setCustomerNameError('Customer Name required')
            error = true
        }
        if (data.customerAddress === '') {
            setCustomerAddressError('Customer Address Required')
            error = true
        }
        if (data.contactNumber === "") {
            setContactNumberError("Contact.No required")
            error = true
        }

        if (!error) {
            if (formType === FormTypeList.ADD) {

                dispatch(addServiceBooking(data)).then(text => {
                    // toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.EDIT && editData !== undefined && editData.id !== undefined) {
                dispatch(editServiceBooking(data, editData.id)).then(text => {
                    // toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }

    useEffect(() => {
        if (formType === FormTypeList.EDIT && editData !== undefined) {
            // console.log(editData.date);

            setDate(editData.date)
            setServiceId(editData.serviceId !== undefined ? editData.serviceId : '')
            setServiceName(editData.serviceName !== undefined ? editData.serviceName : '')
            setBilling(editData.billing as BillingTypes)
            setRate(editData.rate.toString())
            setUnit(editData.unit.toString())
            setAmount(editData.amount.toString())
            setDiscount(editData.discount.toString())
            setFinalAmount(editData.finalAmount.toString())
            setCustomerName(editData.customerName !== undefined ? editData.customerName : '')
            setContactNumber(editData.contactNumber)
            setCustomerAddress(editData.customerAddress)
        } else {
            resetStates()
        }
    }, [formType, editData])


    return <div className='container'>
        <div className="row">

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Service'
                    labelClassName="required"
                    value={serviceId}
                    onChange={handleServiceId}
                    placeholder='Service'
                    errorText={serviceIdError}
                    type="select"
                    containerClass="mb-2"
                >
                    <option value="">Select</option>
                    {services.map((g, i) => {
                        return <Option value={g.id} key={i}>{g.title}</Option>
                    })}
                </FormInput>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Date'
                    labelClassName="required"
                    value={date}
                    onChange={handleDate}
                    placeholder='Date'
                    errorText={dateError}
                    type="date"
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Billing type'
                    labelClassName="required"
                    value={billing}
                    onChange={handleBilling}
                    placeholder='Billing type'
                    errorText={billingError}
                    type="select"
                    containerClass="mb-2"
                >
                    <option value="">Select</option>
                    {BILLING_TYPES.map((bt, i) => {
                        return <Option value={bt.value} key={i}>{bt.text}</Option>
                    })}
                </FormInput>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Rate'
                    labelClassName="required"
                    value={rate}
                    onChange={handleRate}
                    placeholder='Rate'
                    errorText={rateError}
                    type="number"
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Unit'
                    labelClassName="required"
                    value={unit}
                    onChange={handleUnit}
                    placeholder='Unit'
                    errorText={unitError}
                    type="number"
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Amount'
                    labelClassName="required"
                    value={amount}
                    onChange={handleAmount}
                    placeholder='Amount'
                    errorText={amountError}
                    type="number"
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Discount'
                    labelClassName="required"
                    value={discount}
                    onChange={handleDiscount}
                    placeholder='Discount'
                    errorText={discountError}
                    type="number"
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Final Amount'
                    labelClassName="required"
                    value={finalAmount}
                    onChange={handleFinalAmount}
                    placeholder='Final Amount'
                    errorText={finalAmountError}
                    type="number"
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Contact Person'
                    labelClassName="required"
                    value={customerName}
                    onChange={handleCustomerName}
                    placeholder='Contact Person'
                    errorText={customerNameError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Customer Address'
                    labelClassName="required"
                    value={customerAddress}
                    onChange={handleCustomerAddress}
                    placeholder='Customer Address'
                    errorText={customerAddressError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Contact Number'
                    labelClassName="required"
                    value={contactNumber}
                    onChange={handleContactNumber}
                    placeholder='contact Number'
                    errorText={contactNumberError}
                    type="number"
                    containerClass="mb-2"
                />
            </div>

        </div>
        <div className="d-flex justify-content-end hstack gap-1">
            <button className="btn btn-primary" onClick={handleClear}>Clear</button>
            <button className="btn btn-success" onClick={handleSave}>Save</button>
        </div>

    </div >
}
export default ServiceBookingForm