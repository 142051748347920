import { ChangeEventHandler, MouseEventHandler, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { addKey } from "../config/functions"
import { FormType, FormTypeList } from "../pages/Group"
import { addService, BillingTypes, BillingTypesList, editService, ServiceType, ServiceTypes, ServiceTypesList, StatusList, StatusTypes } from "../redux/actions/serviceActions"
import { AppDispatch } from "../redux/store"
import { FormInput } from "./custom/components"
import Option from "./Option"
import { ToastTypes, useToast } from "./toast/Toastprovider"

interface Props {
    formType: FormType
    editData?: ServiceType
    onSave: () => void
}

const SERVICE_TYPES = addKey([
    {
        text: 'Equipment',
        value: 'Equipment'
    },
    {
        text: 'Service',
        value: 'Service'
    },
])

const STATUS = addKey([
    {
        text: 'Booked',
        value: 'Booked'
    },
    {
        text: 'Available',
        value: 'Available'
    },
    {
        text: 'Disabled',
        value: 'Disabled'
    },
])

export const BILLING_TYPES = addKey([
    {
        text: 'Hourly',
        value: 'Hourly'
    },
    {
        text: 'Daily',
        value: 'Daily'
    },
])

//     type: ServiceTypes
//     title: string
//     billing: BillingTypes
//     rate: number
//     contactPerson: string
//     contactNumber: string
//     status: StatusTypes
const ServiceForm = ({ formType, onSave, editData }: Props) => {

    const toast = useToast()
    // const groups = useSelector<StoreState, Array<ServiceType>>(state => (state.service))

    const dispatch = useDispatch<AppDispatch>()

    const [type, setType] = useState<ServiceTypes>(ServiceTypesList.EQUIPMENT)
    const [typeError, setTypeError] = useState<string>('')
    const [title, setTitle] = useState<string>('')
    const [titleError, setTitleError] = useState<string>('')
    const [billing, setBilling] = useState<BillingTypes>(BillingTypesList.HOURLY)
    const [billingError, setBillingError] = useState<string>('')
    const [rate, setRate] = useState<string>('')
    const [rateError, setRateError] = useState<string>('')
    const [contactPerson, setContactPerson] = useState<string>('')
    const [contactPersonError, setContactPersonError] = useState<string>('')
    const [contactNumber, setContactNumber] = useState<string>('')
    const [contactNumberError, setContactNumberError] = useState<string>('')
    const [status, setStatus] = useState<StatusTypes>(StatusList.AVAILABLE)
    const [statusError, setStatusError] = useState<string>('')

    const resetStates = () => {
        setContactPerson('')
        setContactPersonError('')
        setTitle('')
        setTitleError('')
        setStatus(StatusList.AVAILABLE)
        setStatusError('')
        setType(ServiceTypesList.EQUIPMENT)
        setTypeError('')
        setContactNumber('')
        setContactNumberError('')
        setRate('')
        setRateError('')
        setBilling(BillingTypesList.HOURLY)
        setBillingError('')
    }

    const handleContactPerson: ChangeEventHandler<HTMLInputElement> = (e) => {
        setContactPerson(e.target.value)
        setContactPersonError('')
    }

    const handleStatus: ChangeEventHandler<HTMLInputElement> = (e) => {
        setStatus(e.target.value as StatusTypes)
        setStatusError('')
    }

    const handleTitle: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTitle(e.target.value)
        setTitleError('')
    }

    const handleType: ChangeEventHandler<HTMLInputElement> = (e) => {
        setType(e.target.value as ServiceTypes)
        setTypeError('')
    }

    const handleContactNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setContactNumber(e.target.value)
        setContactNumberError('')
    }

    const handleRate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRate(e.target.value)
        setRateError('')
    }

    const handleBilling: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBilling(e.target.value as BillingTypes)
        setBillingError('')
    }


    const handleClear: MouseEventHandler<HTMLButtonElement> = (e) => {
        resetStates()
    }

    const handleSave = () => {
        let error = false
        const data: ServiceType = {
            type: type,
            title: title,
            billing: billing,
            rate: parseInt(rate),
            contactPerson: contactPerson,
            contactNumber: contactNumber,
            status: status
        }

        if ([StatusList.AVAILABLE, StatusList.BOOKED, StatusList.DISABLED].indexOf(data.status as StatusTypes) === -1) {
            setStatusError('Status must be Booked/Available/Disabled')
            error = true
        }

        if ([ServiceTypesList.EQUIPMENT, ServiceTypesList.SERVICE].indexOf(data.type as ServiceTypes) === -1) {
            setTypeError('Type must be EQUIPMENT/SERVICE')
            error = true
        }

        if (data.title === "") {
            setTitleError('Title required')
            error = true
        }

        if ([BillingTypesList.DAILY, BillingTypesList.HOURLY].indexOf(data.billing as BillingTypes) === -1) {
            setBillingError('Billing Type must be Agri HOURLY/DAILY')
            error = true
        }

        if (isNaN(data.rate)) {
            setRateError('Rate required')
            error = true
        } else if (data.rate <= 0) {
            setRateError('Invalid Rate')
            error = true
        }

        if (data.contactPerson === "") {
            setContactPersonError('Contact Person required')
            error = true
        }

        if (data.contactNumber === "") {
            setContactNumberError("Contact.No required")
            error = true
        }

        if (!error) {
            if (formType === FormTypeList.ADD) {

                dispatch(addService(data)).then(text => {
                    // toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.EDIT && editData !== undefined && editData.id !== undefined) {
                dispatch(editService(data, editData.id)).then(text => {
                    // toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }

    useEffect(() => {
        if (formType === FormTypeList.EDIT && editData !== undefined) {

            setType(editData.type)
            setTitle(editData.title)
            setRate(editData.rate.toString())
            setBilling(editData.billing)
            setContactPerson(editData.contactPerson !== undefined ? editData.contactPerson : '')
            setContactNumber(editData.contactNumber.toString())
            setStatus(editData.status)
        } else {
            resetStates()
        }
    }, [formType, editData])


    return <div className='container'>
        <div className="row">

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Type'
                    labelClassName="required"
                    value={type}
                    onChange={handleType}
                    placeholder='Type'
                    errorText={typeError}
                    type="select"
                    containerClass="mb-2"
                >
                    <option value="">Select</option>
                    {SERVICE_TYPES.map((g, i) => {
                        return <Option value={g.value} key={i}>{g.text}</Option>
                    })}
                </FormInput>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Title'
                    labelClassName="required"
                    value={title}
                    onChange={handleTitle}
                    placeholder='Title'
                    errorText={titleError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Billing type'
                    labelClassName="required"
                    value={billing}
                    onChange={handleBilling}
                    placeholder='Billing type'
                    errorText={billingError}
                    type="select"
                    containerClass="mb-2"
                >
                    <option value="">Select</option>
                    {BILLING_TYPES.map((bt, i) => {
                        return <Option value={bt.value} key={i}>{bt.text}</Option>
                    })}
                </FormInput>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Rate'
                    labelClassName="required"
                    value={rate}
                    onChange={handleRate}
                    placeholder='Rate'
                    errorText={rateError}
                    type="number"
                    containerClass="mb-2"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Contact Person'
                    labelClassName="required"
                    value={contactPerson}
                    onChange={handleContactPerson}
                    placeholder='Contact Person'
                    errorText={contactPersonError}
                    containerClass="mb-2"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='contact Number'
                    labelClassName="required"
                    value={contactNumber}
                    onChange={handleContactNumber}
                    placeholder='contact Number'
                    errorText={contactNumberError}
                    type="number"
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Status'
                    labelClassName="required"
                    value={status}
                    onChange={handleStatus}
                    placeholder='Status'
                    errorText={statusError}
                    type="select"
                    containerClass="mb-2"
                >
                    <option value="">Select</option>
                    {STATUS.map((s, i) => {
                        return <Option value={s.value} key={i}>{s.text}</Option>
                    })}
                </FormInput>
            </div>
        </div>
        <div className="d-flex justify-content-end hstack gap-1">
            <button className="btn btn-primary" onClick={handleClear}>Clear</button>
            <button className="btn btn-success" onClick={handleSave}>Save</button>
        </div>

    </div >
}
export default ServiceForm