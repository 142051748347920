import { nanoid } from "@reduxjs/toolkit"
import { Dispatch } from "redux"

export enum ActivityCategoryTypes {
    AGRI_INPUT = 'Agri input',
    FIELD_ACTIVITY = 'Field activity',
    AGRI_OUTPUT = 'Agri output',
}

export type ActivityCategories = ActivityCategoryTypes.AGRI_INPUT | ActivityCategoryTypes.AGRI_OUTPUT | ActivityCategoryTypes.FIELD_ACTIVITY

export interface MemberType {
    id?: string
    name: string
    gender: string
    dob: string
    fatherOrhusbandName: string
    aadhaarNo: string
    contactNo: string
    activityCategory: ActivityCategories
    primaryActivity: string
    groupId?: string
    groupName?: string
    role: string
    status: string
}

export const MemberSampleData = [
    {
        id: '1',
        name: 'Aishu',
        gender: 'Female',
        dob: '2002-10-12',
        fatherOrhusbandName: 'Maniratnam',
        aadhaarNo: '123456789012',
        contactNo: '7634567587',
        activityCategory: ActivityCategoryTypes.AGRI_INPUT,
        primaryActivity: 'Seeds',
        groupId: '1',
        groupName: 'service',
        role: 'member',
        status: 'active',
    },
    {
        id: '2',
        name: 'Ram',
        gender: 'male',
        dob: '2002-10-12',
        fatherOrhusbandName: 'Ratnam',
        aadhaarNo: '123456789012',
        contactNo: '7634567587',
        activityCategory: ActivityCategoryTypes.AGRI_INPUT,
        primaryActivity: 'Seeds',
        groupId: '2',
        groupName: 'service',
        role: 'member',
        status: 'active',
    }
]


export enum MemberActionsList {
    ADD_MEMBER = 'ADD_MEMBER',
    EDIT_MEMBER = 'EDIT_MEMBER',
    FETCH_MEMBER = 'FETCH_MEMBER',
    DELETE_MEMBER = 'DELETE_MEMBER'
}

export interface DeleteMember {
    id: string
}

export interface AddMemberAction {
    type: MemberActionsList.ADD_MEMBER
    data: MemberType
}

export interface EditMemberAction {
    type: MemberActionsList.EDIT_MEMBER
    data: MemberType
    id: string
}

export interface FetchMemberAction {
    type: MemberActionsList.FETCH_MEMBER
    // data: Member[]
}

export interface DeleteMemberAction {
    type: MemberActionsList.DELETE_MEMBER
    data: DeleteMember
}

export type MemberActions = AddMemberAction | EditMemberAction | FetchMemberAction | DeleteMemberAction

export const addMember = (data: MemberType) => {
    return async (dispatch: Dispatch) => {
        return dispatch<AddMemberAction>({
            type: MemberActionsList.ADD_MEMBER,
            data: {
                ...data,
                id: nanoid(5)
            }
        })

    }
}

export const editMember = (data: MemberType, id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<EditMemberAction>({
            type: MemberActionsList.EDIT_MEMBER,
            data: data,
            id: id
        })

    }
}

export const fetchMember = () => {
    return async (dispatch: Dispatch) => {
        return dispatch<FetchMemberAction>({
            type: MemberActionsList.FETCH_MEMBER,
            // data: data
        })

    }
}

export const deleteMember = (id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<DeleteMemberAction>({
            type: MemberActionsList.DELETE_MEMBER,
            data: { id }
        })

    }
}