import { MouseEventHandler, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import PageTitle from "../components/custom/components/PageTitle"
import VerticalLayout from "../components/custom/layouts/Vertical"
import Modal from "../components/Modal"
import ShareManagementForm from "../components/ShareManagementForm"
import { deleteShareManagement, ShareManagementType } from "../redux/actions/shareManagementActions"
import { AppDispatch, StoreState } from "../redux/store"

export enum FormTypeList {
    ADD = 'ADD',
    EDIT = 'EDIT'
}

export type FormType = FormTypeList.ADD | FormTypeList.EDIT;

interface TableBody {
    sno: number
    memberId?: string
    memberName: string
    amount: number
    date: string
    particulars: string
    createTimestamp?: string
    createBy?: string
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
}

type HandleEdit = (value: ShareManagementType) => void
type HandleDelete = (value: ShareManagementType) => void

const ShareManagement = () => {

    const dispatch = useDispatch<AppDispatch>()

    const ShareManagement = useSelector<StoreState, Array<ShareManagementType>>(state => (state.shareManagement))

    const [showForm, setShowForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<ShareManagementType>()

    const handleAdd = () => {
        setShowForm(true)
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.EDIT)
        setEditData(value)
        setShowForm(true)
    }

    const handleDelete: HandleDelete = (value) => {
        if (value.id !== undefined) {
            dispatch(deleteShareManagement(value.id)).then(text => {
                // toast(text)
            }).catch(text => {
                // toast(text, ToastTypes.ERROR)
            })
        }
    }

    const handleFormClose = () => {
        setShowForm(false)
        setFormType(FormTypeList.ADD)
        setEditData(undefined)
    }

    return <><Modal
        headerText={formType === FormTypeList.ADD ? 'Add Share Management' : 'Edit Share Management'}
        visible={showForm}
        onClose={handleFormClose}
        size='xl'
        centered
    >
        <ShareManagementForm
            formType={formType}
            editData={editData}
            onSave={handleFormClose}
        />

    </Modal>
        <VerticalLayout>
            <PageTitle
                title="ShareManagement"
                buttonText="Add"
                onButtonClick={handleAdd}
            />

            <div className="card">
                <div className="card-body">
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Date</th>
                                    <th className='text-truncate align-middle'>Member</th>
                                    <th className='text-truncate align-middle'>Amount</th>
                                    <th className='text-truncate align-middle'>Particulars</th>
                                    <th className='text-truncate align-middle'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ShareManagement.map((sm, i) => {
                                    return <ShareManagementBody
                                        sno={i + 1}
                                        memberName={sm.memberName !== undefined ? sm.memberName : ''}
                                        amount={sm.amount}
                                        date={sm.date}
                                        particulars={sm.particulars}
                                        key={i}
                                        onEdit={() => handleEdit(sm)}
                                        onDelete={() => handleDelete(sm)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </VerticalLayout>
    </>
}

const ShareManagementBody = ({ sno, memberName, amount, date, particulars, onEdit, onDelete }: TableBody) => {
    return <tr>
        <td className='align-middle'>{sno}</td>
        <td className='align-middle'>{date}</td>
        <td className='align-middle'>{memberName}</td>
        <td className='align-middle'>{amount}</td>
        <td className='text-capitalize align-middle'>{particulars}</td>
        {/* <td className='text-capitalize align-middle'>{usp}</td> */}
        <td>
            <div className='hstack gap-1'>
                <button className='btn btn-sm btn-primary' onClick={onEdit}>Edit</button>
                <button className='btn btn-sm btn-danger' onClick={onDelete}>Delete</button>
            </div>
        </td>
    </tr>
}

export default ShareManagement