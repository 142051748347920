import { ServiceActionsList, ServiceSampleData, ServiceType } from "../actions/serviceActions";
import { Action } from "../actions/types";


export const serviceReducer = (state: ServiceType[] = ServiceSampleData, action: Action) => {

    switch (action.type) {

        case ServiceActionsList.ADD_SERVICE: {

            return [...state, action.data]
        }

        case ServiceActionsList.EDIT_SERVICE: {

            const index = state.findIndex(i => i.id === action.id)
            const newState = [...state]

            if (index > -1) {
                newState[index] = {
                    ...action.data,
                    id: action.id
                }
            }
            return newState
        }

        case ServiceActionsList.FETCH_SERVICE: {

            return state
        }

        case ServiceActionsList.DELETE_SERVICE: {
            return state.filter(i => i.id !== action.data.id)

        }

        default: return state
    }

}