import { nanoid } from 'nanoid'
import React from 'react'
import { AlertCircle, CheckCircle } from 'react-feather'
import './toast.scss'

export enum ToastTypes {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR'
}

type ToastType = ToastTypes.SUCCESS | ToastTypes.ERROR
type Toast = (text: string, type?: ToastType) => void
type RemoveToast = (key: string) => void
type UseToast = () => Toast

interface Props {
    children: React.ReactNode
}

interface ToastItemType {
    text: string
    type: ToastType
}

interface Message extends ToastItemType {
    key: string
}

interface ToastContextValue {
    toast: Toast
}

const initialValue: ToastContextValue = {
    toast: () => { }
}

const ToastContext: React.Context<ToastContextValue> = React.createContext(initialValue)

const ToastProvider: React.FC<Props> = ({ children }) => {
    const [messageList, setMessageList] = React.useState<Array<Message>>([])

    const setToast: Toast = (text, type = ToastTypes.SUCCESS) => {
        const key = nanoid()

        setMessageList(prev => {
            return [{
                key: key,
                text: text,
                type: type
            }, ...prev].slice(0, 4)
        })

        setTimeout(() => {
            removeToast(key)
        }, 10000)
    }

    const removeToast: RemoveToast = (key) => {
        setMessageList(prev => {
            return prev.filter(p => p.key !== key)
        })
    }

    return <ToastContext.Provider value={{ toast: setToast }}>
        {messageList.length > 0 && <div className='sbs-toast-container overflow-hidden px-3 py-2'>
            {messageList.map((msg) => {
                return <ToastItem
                    key={msg.key}
                    text={msg.text}
                    type={msg.type}
                />
            })}
        </div>}
        {children}
    </ToastContext.Provider>
}

const ToastItem: React.FC<ToastItemType> = ({ text, type }) => {
    const [animationClass, setAnimationClass] = React.useState('slide-in')

    React.useEffect(() => {
        const timeoutId = setTimeout(() => {
            setAnimationClass('slide-out')
        }, 9800)

        return () => clearTimeout(timeoutId)
    }, [])

    return <div className={`${animationClass} bg-dark text-white rounded my-2 px-3 py-2 shadow-sm hstack gap-1`}>
        <div style={{ width: '30px' }}>
            {type === ToastTypes.SUCCESS
                ? <CheckCircle size={23} className='text-success' />
                : type === ToastTypes.ERROR
                    ? <AlertCircle size={23} className='text-danger' />
                    : ''}
        </div>
        <div className='ms-3'>{text}</div>
    </div>
}

export const useToast: UseToast = () => {
    const { toast } = React.useContext(ToastContext)
    return typeof toast === 'function' ? toast : () => { }
}

export default ToastProvider