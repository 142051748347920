import { ChangeEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FormType, FormTypeList } from "../pages/Group"
import { addGroup, editGroup, Group } from "../redux/actions/groupActions"
import { AppDispatch, StoreState } from "../redux/store"
import { FormInput } from "./custom/components"
import { ToastTypes, useToast } from "./toast/Toastprovider"


interface Props {
    formType: FormType
    editData?: Group
    onSave: () => void
}

const GroupForm = ({ formType, onSave, editData }: Props) => {
    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()
    const groups = useSelector<StoreState, Array<Group>>(state => (state.group))

    const [name, setName] = useState<string>('')
    const [nameError, setNameError] = useState<string>('')
    const [usp, setUsp] = useState<string>('')
    const [uspError, setUspError] = useState<string>('')
    const [location, setLocation] = useState<string>('')
    const [locationError, setLocationError] = useState<string>('')

    const resetStates = () => {
        setName('')
        setNameError('')
        setUsp('')
        setUspError('')
        setLocation('')
        setLocationError('')
    }

    const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setName(e.target.value)
        setNameError('')
    }

    const handleUsp: ChangeEventHandler<HTMLInputElement> = (e) => {
        setUsp(e.target.value)
        setUspError('')
    }

    const handleLocation: ChangeEventHandler<HTMLInputElement> = (e) => {
        setLocation(e.target.value)
        // const index = routeMaster.findIndex(rm => rm.id !== undefined && rm.id.toString() === e.target.value)

        // if (index > -1) {
        //     setRouteName(routeMaster[index].name)
        // }
        setLocationError('')
    }

    const handleClear = () => {
        resetStates()
    }

    const handleSave = () => {
        let error = false
        const data: Group = {
            name: name,
            location: location,
            usp: usp
        }

        if (data.name === '') {
            setNameError('Name required')
            error = true
        } else if ((data.name).length > 15) {
            setNameError('Invalid Name')
            error = true
        }

        if (data.location === '') {
            setLocationError('Location required')
            error = true
        } else if ((data.location).length > 15) {
            setLocationError('Invalid Location')
            error = true
        }

        if (data.usp === '') {
            setUspError('usp required')
            error = true
        } else if ((data.usp).length > 15) {
            setUspError('Invalid usp')
            error = true
        }

        if (!error) {
            if (formType === FormTypeList.ADD) {

                dispatch(addGroup(data)).then(text => {
                    // toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.EDIT && editData !== undefined && editData.id !== undefined) {
                dispatch(editGroup(data, editData.id)).then(text => {
                    // toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }

    useEffect(() => {
        if (formType === FormTypeList.EDIT && editData !== undefined) {
            setUsp(editData.usp)
            setLocation(editData.location)
            setName(editData.name)
            // setRouteName(editData.routeName !== undefined ? editData.routeName : '')
        } else {
            resetStates()
        }
    }, [formType, editData])


    return <div className='container'>
        <div className="row">

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Name'
                    labelClassName="required"
                    value={name}
                    onChange={handleName}
                    placeholder='Name'
                    errorText={nameError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Location'
                    labelClassName="required"
                    value={location}
                    onChange={handleLocation}
                    placeholder='Location'
                    errorText={locationError}
                    containerClass="mb-2"
                />
            </div>


            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='USP'
                    labelClassName="required"
                    value={usp}
                    onChange={handleUsp}
                    placeholder='USP'
                    errorText={uspError}
                    containerClass="mb-2"
                />
            </div>
        </div>

        <div className="d-flex justify-content-end hstack gap-1 mt-2">
            <button className="btn btn-primary" onClick={handleClear}>Clear</button>
            <button className="btn btn-success" onClick={handleSave}>Save</button>
        </div>

    </div >
}
export default GroupForm