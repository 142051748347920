import { ChangeEventHandler, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { FormType, FormTypeList } from "../pages/Group"
import { addSupplier, editSupplier, Supplier } from "../redux/actions/supplierActions"
import { TransactionType } from "../redux/actions/transactionActions"
import { AppDispatch } from "../redux/store"
import { FormInput } from "./custom/components"
import { ToastTypes, useToast } from "./toast/Toastprovider"


interface Props {
    formType: FormType
    editData?: Supplier
    onSave: () => void
}

const SupplierForm = ({ formType, onSave, editData }: Props) => {
    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()

    const [debitData, setDebitData] = useState<TransactionType>()
    const [creditData, setCreditData] = useState<TransactionType>()
    // const [editDebitData, setEditDebitData] = useState<TransactionType>()
    // const [editCreditData, setEditCreditData] = useState<TransactionType>()

    const [code, setCode] = useState<string>('')
    const [codeError, setCodeError] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [nameError, setNameError] = useState<string>('')
    const [address, setAddress] = useState<string>('')
    const [addressError, setAddressError] = useState<string>('')
    const [contactPerson, setContactPerson] = useState<string>('')
    const [contactPersonError, setContactPersonError] = useState<string>('')
    const [mobileNumber, setMobileNumber] = useState<string>('')
    const [mobileNumberError, setMobileNumberError] = useState<string>('')
    const [gstNumber, setGstNumber] = useState<string>('')
    const [gstNumberError, setGstNumberError] = useState<string>('')
    const [aadhaarNumber, setAadhaarNumber] = useState<string>('')
    const [aadhaarNumberError, setAadhaarNumberError] = useState<string>('')
    const [panNumber, setPanNumber] = useState<string>('')
    const [panNumberError, setPanNumberError] = useState<string>('')
    const [oldBalance, setOldBalance] = useState<string>('')
    const [oldBalanceError, setOldBalanceError] = useState<string>('')
    const [oldBalanceDate, setOldBalanceDate] = useState<string>('')
    const [oldBalanceDateError, setOldBalanceDateError] = useState<string>('')

    const resetStates = () => {
        setCode('')
        setCodeError('')
        setName('')
        setNameError('')
        setAddress('')
        setAddressError('')
        setContactPerson('')
        setContactPersonError('')
        setMobileNumber('')
        setMobileNumberError('')
        setGstNumber('')
        setGstNumberError('')
        setAadhaarNumber('')
        setAadhaarNumberError('')
        setPanNumber('')
        setPanNumberError('')
        setOldBalance('')
        setOldBalanceError('')
        setOldBalanceDate('')
        setOldBalanceDateError('')
    }

    const handleCode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCode(e.target.value)
        setCodeError('')
    }

    const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setName(e.target.value)
        setNameError('')
    }

    const handleAddress: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAddress(e.target.value)
        setAddressError('')
    }

    const handleContactPerson: ChangeEventHandler<HTMLInputElement> = (e) => {
        setContactPerson(e.target.value)
        setContactPersonError('')
    }
    const handleMobileNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setMobileNumber(e.target.value)
        setMobileNumberError('')
    }
    const handleGstNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setGstNumber(e.target.value)
        setGstNumberError('')
    }
    const handleAadhaarNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAadhaarNumber(e.target.value)
        setAadhaarNumberError('')
    }
    const handlePanNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPanNumber(e.target.value)
        setPanNumberError('')
    }
    const handleOldBalance: ChangeEventHandler<HTMLInputElement> = (e) => {
        setOldBalance(e.target.value)
        setOldBalanceError('')
    }
    const handleOldBalanceDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setOldBalanceDate(e.target.value)
        setOldBalanceDateError('')
    }

    const handleClear = () => {
        resetStates()
    }

    const handleSave = () => {
        let error = false
        const data: Supplier = {
            code: code,
            name: name,
            address: address,
            contactPerson: contactPerson,
            mobileNumber: mobileNumber,
            gstNumber: gstNumber,
            aadhaarNumber: aadhaarNumber,
            panNumber: panNumber,
            oldBalance: parseFloat(oldBalance),
            oldBalanceDate: oldBalanceDate,

        }
        if (data.code === '') {
            setCodeError('Code required')
            error = true
        } else if ((data.code).length > 15) {
            setCodeError('Invalid Code')
            error = true
        }
        if (data.name === '') {
            setNameError('Name required')
            error = true
        } else if ((data.name).length > 15) {
            setNameError('Name should be atleast 15 letters')
            error = true
        }
        if (data.address === '') {
            setAddressError('Address required')
            error = true
        } else if ((data.address).length > 15) {
            setAddressError('Address should be atleast 15 letters')
            error = true
        }
        if (data.contactPerson === '') {
            setContactPersonError('Contact Person required')
            error = true
        } else if ((data.contactPerson).length > 15) {
            setContactPersonError('Invalid Contact Person')
            error = true
        }
        if (data.mobileNumber === '') {
            setMobileNumberError('Mobile Number required')
            error = true
        } else if ((data.mobileNumber).length > 15) {
            setMobileNumberError('Invalid Mobile Number')
            error = true
        }

        if (data.gstNumber !== '') {
            if ((data.gstNumber)?.length !== 15) {
                setGstNumberError('Invalid GST Number')
                error = true
            }
        }
        if (data.aadhaarNumber !== '' && data.aadhaarNumber !== undefined) {
            if ((data.aadhaarNumber)?.length > 14) {
                setAadhaarNumberError('Invalid Aadhaar Number')
                error = true
            }
        }
        if (data.panNumber !== '') {
            if ((data.panNumber)?.length !== 10) {
                setPanNumberError('Invalid Pan Number')
                error = true
            }
        }

        if (data.oldBalance !== undefined && oldBalance !== "") {
            if (isNaN(data.oldBalance)) {
                setOldBalanceError('Old Balance required')
                error = true
            }
            else if ((data.oldBalance) < 0) {
                setOldBalanceError('Invalid Old Balance')
                error = true
            }
        }

        if (data.oldBalance !== undefined && oldBalance !== "") {
            if (data.oldBalanceDate === '') {
                setOldBalanceDateError('Old Balance Date required')
                error = true
            } else if (data.oldBalanceDate !== undefined && (data.oldBalanceDate)?.length > 10) {
                setOldBalanceDateError('Invalid Old Balance Date')
                error = true
            }
        }


        if (!error) {
            // const CapitalAccountNo = '900004';
            // const CapitalAccountName = 'Capital Account';
            // let Number = nanoid();
            if (formType === FormTypeList.ADD) {

                dispatch(addSupplier(data)).then(text => {

                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })

                // setDebitData({
                //     number: Number,
                //     orderNumber: 1,
                //     financialYear: data.financialYear,
                //     branch: 1,
                //     voucherType: VoucherTypes.PAYMENT,
                //     paymentMode: PaymentModes.CASH,
                //     refNo: '',
                //     transactionFor: TransactionTypes.GENERAL,
                //     source: '',
                //     sourceId: 0,
                //     accountNumber: CapitalAccountNo,
                //     accountName: CapitalAccountName,
                //     accountType: 'main',
                //     date: data.oldBalanceDate,
                //     particulars: 'OLD BALANCE',
                //     debitAmount: data.oldBalance,
                //     creditAmount: '0',
                //     createdBy: 'admin'
                // })
                // setCreditData({
                //     number: Number,
                //     orderNumber: 2,
                //     financialYear: data.financialYear,
                //     branch: 1,
                //     voucherType: VoucherTypes.PAYMENT,
                //     paymentMode: PaymentModes.CASH,
                //     refNo: '',
                //     transactionFor: TransactionTypes.GENERAL,
                //     source: '',
                //     sourceId: 0,
                //     accountNumber: data.paymentMode === PaymentModes.CASH ? CashAccountNo : bankNumber,
                //     accountName: data.name,
                //     accountType: 'main',
                //     date: data.oldBalanceDate,
                //     particulars: 'OLD BALANCE',
                //     debitAmount: '0',
                //     creditAmount: data.oldBalance,
                //     createdBy: 'admin'
                // })

                // if (debitData !== undefined && creditData !== undefined) {
                //     dispatch(addTransaction(debitData))
                //     dispatch(addTransaction(creditData))
                // }

            } else if (formType === FormTypeList.EDIT && editData !== undefined && editData.id !== undefined) {
                dispatch(editSupplier(data, editData.id)).then(text => {

                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })

                // setDebitData({
                //     number: Number,
                //     orderNumber: 1,
                //     financialYear: data.financialYear,
                //     branch: 1,
                //     voucherType: VoucherTypes.PAYMENT,
                //     paymentMode: PaymentModes.CASH,
                //     refNo: '',
                //     transactionFor: TransactionTypes.GENERAL,
                //     source: '',
                //     sourceId: 0,
                //     accountNumber: CapitalAccountNo,
                //     accountName: CapitalAccountName,
                //     accountType: 'main',
                //     date: data.oldBalanceDate,
                //     particulars: 'OLD BALANCE',
                //     debitAmount: data.oldBalance,
                //     creditAmount: '0',
                //     createdBy: 'admin'
                // })
                // setCreditData({
                //     number: Number,
                //     orderNumber: 2,
                //     financialYear: data.financialYear,
                //     branch: 1,
                //     voucherType: VoucherTypes.PAYMENT,
                //     paymentMode: PaymentModes.CASH,
                //     refNo: '',
                //     transactionFor: TransactionTypes.GENERAL,
                //     source: '',
                //     sourceId: 0,
                //     accountNumber: data.paymentMode === PaymentModes.CASH ? CashAccountNo : bankNumber,
                //     accountName: data.name,
                //     accountType: 'main',
                //     date: data.oldBalanceDate,
                //     particulars: 'OLD BALANCE',
                //     debitAmount: '0',
                //     creditAmount: data.oldBalance,
                //     createdBy: 'admin'
                // })

                // if (debitData !== undefined && creditData !== undefined) {
                //     dispatch(addTransaction(debitData))
                //     dispatch(addTransaction(creditData))
                // }
            }
        }
    }

    useEffect(() => {
        if (formType === FormTypeList.EDIT && editData !== undefined) {
            setCode(editData.code)
            setName(editData.name)
            setAddress(editData.address)
            setContactPerson(editData.contactPerson)
            setMobileNumber(editData.mobileNumber)
            setGstNumber(editData.gstNumber !== undefined ? editData.gstNumber : "")
            setAadhaarNumber(editData.aadhaarNumber !== undefined ? editData.aadhaarNumber : '')
            setPanNumber(editData.panNumber !== undefined ? editData.panNumber : '')
            setOldBalance(editData.oldBalance !== undefined ? editData.oldBalance.toString() : '')
            setOldBalanceDate(editData.oldBalanceDate !== undefined ? editData.oldBalanceDate : '')


        } else {
            resetStates()
        }
    }, [formType, editData])


    return <div className='container'>
        <div className="row">
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Code'
                    labelClassName="required"
                    value={code}
                    onChange={handleCode}
                    placeholder='Code'
                    errorText={codeError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Name'
                    labelClassName="required"
                    value={name}
                    onChange={handleName}
                    placeholder='Name'
                    errorText={nameError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Address'
                    labelClassName="required"
                    value={address}
                    onChange={handleAddress}
                    placeholder='Address'
                    errorText={addressError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Contact Person'
                    labelClassName="required"
                    value={contactPerson}
                    onChange={handleContactPerson}
                    placeholder='Contact Person'
                    errorText={contactPersonError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Mobile Number'
                    labelClassName="required"
                    value={mobileNumber}
                    onChange={handleMobileNumber}
                    placeholder='Mobile Number'
                    errorText={mobileNumberError}
                    containerClass="mb-2"
                    type="number"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='GST Number'
                    value={gstNumber}
                    onChange={handleGstNumber}
                    placeholder='GST Number'
                    errorText={gstNumberError}
                    containerClass="mb-2"

                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Aadhaar Number'
                    value={aadhaarNumber}
                    onChange={handleAadhaarNumber}
                    placeholder='Aadhaar Number'
                    errorText={aadhaarNumberError}
                    containerClass="mb-2"
                    type="number"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='PAN Number'
                    value={panNumber}
                    onChange={handlePanNumber}
                    placeholder='PAN Number'
                    errorText={panNumberError}
                    containerClass="mb-2"

                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Old Balance'
                    value={oldBalance}
                    onChange={handleOldBalance}
                    placeholder='Old Balance'
                    errorText={oldBalanceError}
                    containerClass="mb-2"
                    type="number"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Old Balance Date'
                    value={oldBalanceDate}
                    onChange={handleOldBalanceDate}
                    placeholder='Old Balance Date'
                    errorText={oldBalanceDateError}
                    containerClass="mb-2"
                    type="date"
                />
            </div>

        </div>

        <div className="d-flex justify-content-end hstack gap-1 mt-2">
            <button className="btn btn-primary" onClick={handleClear}>Clear</button>
            <button className="btn btn-success" onClick={handleSave}>Save</button>
        </div>

    </div >
}
export default SupplierForm








