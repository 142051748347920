import { nanoid } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import { UnitTypes } from "./productActions"
import { UnitDropDown } from "./productActions" 


export interface Sale {
    id?: string
    productId?: string
    productName:string
    price: number
    unit: UnitTypes
    amount: number
    quantity:number
    customerName: string
    customerAddress: string
    contactNumber: string
    date: string
    createTimestamp?: string
    createdBy?: string
}

export const SaleSampleData = [
    {
        id: '1',
        productId: "1",
        productName:'ponnirice',
        price: 750,
        unit:UnitDropDown.BAG_10KG,
        quantity:2,
        amount:1500,
        customerName:"catharin",
        customerAddress:"Koradacheri",
        contactNumber:"9876543210",
        date:"2022-12-20",
    },
    {
        id: '2',
        productId:"2",
        productName:'ponnirice',
        price:200,
        unit:UnitDropDown.BAG_10KG,
        quantity:3,
        amount:600,
        customerName:"prasanna",
        customerAddress:"thiruvarur",
        contactNumber:"9876543210",
        date:"2022-12-12",
    },

]

export enum SaleActionsList {
    ADD_SALE = 'ADD_SALE',
    EDIT_SALE = 'EDIT_SALE',
    FETCH_SALE = 'FETCH_SALE',
    DELETE_SALE = 'DELETE_SALE'
}

export interface DeleteSale {
    id: string
}

export interface AddSaleAction {
    type: SaleActionsList.ADD_SALE
    data: Sale
}

export interface EditSaleAction {
    type: SaleActionsList.EDIT_SALE
    data: Sale
    id: string
}

export interface FetchSaleAction {
    type: SaleActionsList.FETCH_SALE
    // data: Sale[]
}

export interface DeleteSaleAction {
    type: SaleActionsList.DELETE_SALE
    data: DeleteSale
}

export type SaleActions = AddSaleAction | EditSaleAction | FetchSaleAction | DeleteSaleAction

export const addSale = (data: Sale) => {
    return async (dispatch: Dispatch) => {
        return dispatch<AddSaleAction>({
            type: SaleActionsList.ADD_SALE,
            data: {
                ...data,
                id: nanoid(5)
            }
        })
    }
}

export const editSale = (data: Sale, id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<EditSaleAction>({
            type: SaleActionsList.EDIT_SALE,
            data: data,
            id: id
        })
    }
}

export const fetchSale = () => {
    return async (dispatch: Dispatch) => {
        return dispatch<FetchSaleAction>({
            type: SaleActionsList.FETCH_SALE,
            // data: response.data
        })
    }
}

export const deleteSale = (id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<DeleteSaleAction>({
            type: SaleActionsList.DELETE_SALE,
            data: { id }
        })
    }
}