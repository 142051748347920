import { MouseEventHandler, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import PageTitle from "../components/custom/components/PageTitle"
import VerticalLayout from "../components/custom/layouts/Vertical"
import SupplierForm from "../components/SupplierForm"
import Modal from "../components/Modal"
import { deleteSupplier, Supplier } from "../redux/actions/supplierActions"
import { AppDispatch, StoreState } from "../redux/store"
import { FormType, FormTypeList } from "./Group"


interface TableBody {
    sno: number
    code: string
    name: string
    address: string
    contactPerson: string
    mobileNumber: string
    gstNumber?: string
    aadhaarNumber?: string
    panNumber?: string
    oldBalance?: number
    oldBalanceDate?: string
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
}

type HandleEdit = (value: Supplier) => void
type HandleDelete = (value: Supplier) => void

const Suppliers = () => {

    const dispatch = useDispatch<AppDispatch>()

    const suppliers = useSelector<StoreState, Array<Supplier>>(state => (state.supplier))

    const [showForm, setShowForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<Supplier>()

    const handleAdd = () => {
        setShowForm(true)
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.EDIT)
        setEditData(value)
        setShowForm(true)
    }

    const handleDelete: HandleDelete = (value) => {
        if (value.id !== undefined) {
            dispatch(deleteSupplier(value.id)).then(text => {
            }).catch(text => {
            })
        }
    }

    const handleFormClose = () => {
        setShowForm(false)
        setFormType(FormTypeList.ADD)
        setEditData(undefined)
    }

    return <><Modal
        headerText={formType === FormTypeList.ADD ? 'Add Supplier' : 'Edit Supplier'}
        visible={showForm}
        onClose={handleFormClose}
        size='xl'
        centered
    >
        <SupplierForm
            formType={formType}
            editData={editData}
            onSave={handleFormClose}
        />

    </Modal>
        <VerticalLayout>
            <PageTitle
                title="Supplier"
                buttonText="Add"
                onButtonClick={handleAdd}
            />

            <div className="card">
                <div className="card-body">
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Code</th>
                                    <th className='text-truncate align-middle'>Name</th>
                                    <th className='text-truncate align-middle'>Address</th>
                                    <th className='text-truncate align-middle'>Contact Person</th>
                                    <th className='text-truncate align-middle'>Mobile Number</th>
                                    <th className='text-truncate align-middle'>GST Number</th>
                                    <th className='text-truncate align-middle'>Aadhaar Number</th>
                                    <th className='text-truncate align-middle'>PAN Number</th>
                                    <th className='text-truncate align-middle'>Old Balance</th>
                                    <th className='text-truncate align-middle'>Old Balance Date</th>
                                    <th className='text-truncate align-middle'>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {suppliers.map((u, i) => {
                                    return <SupplierBody
                                        sno={i + 1}
                                        code={u.code}
                                        name={u.name}
                                        address={u.address}
                                        contactPerson={u.contactPerson}
                                        mobileNumber={u.mobileNumber}
                                        gstNumber={u.gstNumber}
                                        aadhaarNumber={u.aadhaarNumber}
                                        panNumber={u.panNumber}
                                        oldBalance={u.oldBalance}
                                        oldBalanceDate={u.oldBalanceDate}
                                        key={i}
                                        onEdit={() => handleEdit(u)}
                                        onDelete={() => handleDelete(u)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </VerticalLayout>
    </>
}

const SupplierBody = ({ sno, code, name, address, contactPerson, mobileNumber, gstNumber, aadhaarNumber, panNumber, oldBalance, oldBalanceDate, onEdit, onDelete }: TableBody) => {
    return <tr>
        <td className='align-middle'>{sno}</td>
        <td className='align-middle'>{code}</td>
        <td className='align-middle'>{name}</td>
        <td className='align-middle'>{address}</td>
        <td className='align-middle'>{contactPerson}</td>
        <td className='align-middle'>{mobileNumber}</td>
        <td className='align-middle'>{gstNumber}</td>
        <td className='align-middle'>{aadhaarNumber}</td>
        <td className='align-middle'>{panNumber}</td>
        <td className='align-middle'>{oldBalance}</td>
        <td className='align-middle'>{oldBalanceDate}</td>
        <td>
            <div className='hstack gap-1'>
                <button className='btn btn-sm btn-primary' onClick={onEdit}>Edit</button>
                <button className='btn btn-sm btn-danger' onClick={onDelete}>Delete</button>
            </div>
        </td>
    </tr>
}

export default Suppliers