import { ChangeEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addKey } from "../config/functions"
import { FormType, FormTypeList } from "../pages/Group"
import { MemberType } from "../redux/actions/memberActions"
import { addShareManagement, editShareManagement, ShareManagementType } from "../redux/actions/shareManagementActions"
import { Key } from "../redux/actions/types"
import { AppDispatch, StoreState } from "../redux/store"
import { FormInput } from "./custom/components"
import Option from "./Option"
import { ToastTypes, useToast } from "./toast/Toastprovider"


interface Props {
    formType: FormType
    editData?: ShareManagementType
    onSave: () => void
}

const ShareManagementForm = ({ formType, onSave, editData }: Props) => {
    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()
    const MemberList = useSelector<StoreState, Array<MemberType & Key>>(state => addKey(state.member))

    const [memberId, setMemberId] = useState<string>('')
    const [memberIdError, setMemberIdError] = useState<string>('')
    const [memberName, setMemberName] = useState<string>('')
    const [date, setDate] = useState<string>('')
    const [dateError, setDateError] = useState<string>('')
    const [amount, setAmount] = useState<string>('')
    const [amountError, setAmountError] = useState<string>('')
    const [particulars, setParticulars] = useState<string>('')
    const [particularsError, setParticularsError] = useState<string>('')

    const resetStates = () => {
        setMemberId('')
        setMemberIdError('')
        setMemberName('')
        setDate('')
        setDateError('')
        setAmount('')
        setAmountError('')
        setParticulars('')
        setParticularsError('')
    }

    const handleMemberId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setMemberId(e.target.value)
        const index = MemberList.findIndex(sm => sm.id !== undefined && sm.id === e.target.value)

        if (index > -1) {
            setMemberName(MemberList[index].name)
        }
        setMemberIdError('')
    }

    const handleAmount: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAmount(e.target.value)
        setAmountError('')
    }

    const handleDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDate(e.target.value)
        setDateError('')
    }

    const handleParticulars: ChangeEventHandler<HTMLInputElement> = (e) => {
        setParticulars(e.target.value)
        setParticularsError('')
    }

    const handleClear = () => {
        resetStates()
    }

    const handleSave = () => {
        console.log(memberId);
        console.log(memberName);

        let error = false
        const data: ShareManagementType = {
            memberId: memberId,
            memberName: memberName,
            particulars: particulars,
            amount: parseFloat(amount),
            date: date
        }

        if (data.memberId === '') {
            setMemberIdError('Member required')
            error = true
        }

        if (data.date === '') {
            setDateError('Date required')
            error = true
        }

        if (data.particulars === '') {
            setParticularsError('Particulars required')
            error = true
        } else if ((data.particulars).length > 15) {
            setParticularsError('Invalid Particulars')
            error = true
        }

        if (isNaN(data.amount)) {
            setAmountError('Amount required')
            error = true
        } else if ((data.amount) < 0) {
            setAmountError('Invalid Amount')
            error = true
        }

        if (!error) {
            if (formType === FormTypeList.ADD) {

                dispatch(addShareManagement(data)).then(text => {
                    // toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.EDIT && editData !== undefined && editData.id !== undefined) {
                dispatch(editShareManagement(data, editData.id)).then(text => {
                    // toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }

    useEffect(() => {
        if (formType === FormTypeList.EDIT && editData !== undefined) {
            setDate(editData.date !== undefined ? editData.date : '')
            setMemberId(editData.memberId !== undefined ? editData.memberId : '')
            setMemberName(editData.memberName !== undefined ? editData.memberName : '')
            setAmount(editData.amount.toString())
            setParticulars(editData.particulars)
        } else {
            resetStates()
        }
    }, [formType, editData])


    return <div className='container'>
        <div className="row">

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Date'
                    labelClassName="required"
                    value={date}
                    onChange={handleDate}
                    placeholder='Date'
                    errorText={dateError}
                    containerClass="mb-2"
                    type="date"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Member'
                    labelClassName="required"
                    value={memberId}
                    onChange={handleMemberId}
                    placeholder='Member'
                    errorText={memberIdError}
                    containerClass="mb-2"
                    type="select"
                >
                    <option value=''>Select</option>
                    {MemberList.map(m => {
                        return <Option value={m.id} key={m.key}>{m.name}</Option>

                    })}
                </FormInput>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Amount'
                    labelClassName="required"
                    value={amount}
                    onChange={handleAmount}
                    placeholder='Amount'
                    errorText={amountError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Particulars'
                    labelClassName="required"
                    value={particulars}
                    onChange={handleParticulars}
                    placeholder='Particulars'
                    errorText={particularsError}
                    containerClass="mb-2"
                />
            </div>
        </div>

        <div className="d-flex justify-content-end hstack gap-1 mt-2">
            <button className="btn btn-primary" onClick={handleClear}>Clear</button>
            <button className="btn btn-success" onClick={handleSave}>Save</button>
        </div>

    </div >
}
export default ShareManagementForm