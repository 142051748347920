import { Provider } from 'react-redux'
import Router from './components/Router'
import ToastProvider from './components/toast/Toastprovider'
import store from './redux/store'

const App = () => {
    return <Provider store={store}>
        <ToastProvider>
            <Router />
        </ToastProvider>
    </Provider>
}

export default App
