import { nanoid } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import { BillingTypesList } from "./serviceActions"

export interface BookingType {

    id?: string
    serviceId?: string
    serviceName?: string
    customerName: string
    customerAddress: string
    contactNumber: string
    billing: string
    rate: number
    unit: number
    amount: number
    discount: number
    finalAmount: number
    date: string
    createTimestamp?: string
    createBy?: string
}

export const BookingSampleData = [
    {
        id: '1',
        serviceId: '1',
        serviceName: 'Tractor',
        customerName: 'Sathish',
        customerAddress: '74/633,AK nagar, Trichy',
        contactNumber: '7647567677',
        billing: BillingTypesList.HOURLY,
        rate: 100,
        unit: 2,
        amount: 200,
        discount: 0,
        finalAmount: 200,
        date: '2022-12-08',
        createTimestamp: '1670461666',
        createBy: 'member',
    },
    {
        id: '2',
        serviceId: '1',
        serviceName: 'Tractor',
        customerName: 'Kathir',
        customerAddress: '74,RR nagar, Thanjavur',
        contactNumber: '6647563237',
        billing: BillingTypesList.DAILY,
        rate: 500,
        unit: 2,
        amount: 1000,
        discount: 100,
        finalAmount: 900,
        date: '2022-12-07',
        createTimestamp: '1670375266',
        createBy: 'member',
    },
]

export enum ServiceBookingActionsList {
    ADD_BOOKING = 'ADD_BOOKING',
    EDIT_BOOKING = 'EDIT_BOOKING',
    FETCH_BOOKING = 'FETCH_BOOKING',
    DELETE_BOOKING = 'DELETE_BOOKING'
}

export interface ServiceDeleteBooking {
    id: string
}

export interface ServiceAddBookingAction {
    type: ServiceBookingActionsList.ADD_BOOKING
    data: BookingType
}

export interface ServiceEditBookingAction {
    type: ServiceBookingActionsList.EDIT_BOOKING
    data: BookingType
    id: string
}

export interface ServiceFetchBookingAction {
    type: ServiceBookingActionsList.FETCH_BOOKING
    // data: ServiceBooking[]
}

export interface ServiceDeleteBookingAction {
    type: ServiceBookingActionsList.DELETE_BOOKING
    data: ServiceDeleteBooking
}

export type ServiceBookingActions = ServiceAddBookingAction | ServiceEditBookingAction | ServiceFetchBookingAction | ServiceDeleteBookingAction

export const addServiceBooking = (data: BookingType) => {
    return async (dispatch: Dispatch) => {
        return dispatch<ServiceAddBookingAction>({
            type: ServiceBookingActionsList.ADD_BOOKING,
            data: {
                ...data,
                id: nanoid(5)
            }
        })
    }
}

export const editServiceBooking = (data: BookingType, id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<ServiceEditBookingAction>({
            type: ServiceBookingActionsList.EDIT_BOOKING,
            data: data,
            id: id
        })
    }
}

export const fetchServiceBooking = () => {
    return async (dispatch: Dispatch) => {
        return dispatch<ServiceFetchBookingAction>({
            type: ServiceBookingActionsList.FETCH_BOOKING
            // data: response.data
        })
    }
}

export const deleteServiceBooking = (id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<ServiceDeleteBookingAction>({
            type: ServiceBookingActionsList.DELETE_BOOKING,
            data: { id }
        })
    }
}