import { MouseEventHandler, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import PageTitle from "../components/custom/components/PageTitle"
import VerticalLayout from "../components/custom/layouts/Vertical"
import GroupForm from "../components/GroupForm"
import Modal from "../components/Modal"
import { deleteGroup, Group } from "../redux/actions/groupActions"
import { AppDispatch, StoreState } from "../redux/store"

export enum FormTypeList {
    ADD = 'ADD',
    EDIT = 'EDIT'
}

export type FormType = FormTypeList.ADD | FormTypeList.EDIT;

interface TableBody {
    sno: number
    name: string
    location: string
    usp: string
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
}

type HandleEdit = (value: Group) => void
type HandleDelete = (value: Group) => void

const Groups = () => {

    const dispatch = useDispatch<AppDispatch>()

    const groups = useSelector<StoreState, Array<Group>>(state => (state.group))

    const [showForm, setShowForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<Group>()

    const handleAdd = () => {
        setShowForm(true)
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.EDIT)
        setEditData(value)
        setShowForm(true)
    }

    const handleDelete: HandleDelete = (value) => {
        if (value.id !== undefined) {
            dispatch(deleteGroup(value.id)).then(text => {
                // toast(text)
            }).catch(text => {
                // toast(text, ToastTypes.ERROR)
            })
        }
    }

    const handleFormClose = () => {
        setShowForm(false)
        setFormType(FormTypeList.ADD)
        setEditData(undefined)
    }

    return <><Modal
        headerText={formType === FormTypeList.ADD ? 'Add Group' : 'Edit Group'}
        visible={showForm}
        onClose={handleFormClose}
        size='xl'
        centered
    >
        <GroupForm
            formType={formType}
            editData={editData}
            onSave={handleFormClose}
        />

    </Modal>
        <VerticalLayout>
            <PageTitle
                title="Group"
                buttonText="Add"
                onButtonClick={handleAdd}
            />

            <div className="card">
                <div className="card-body">
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Name</th>
                                    <th className='text-truncate align-middle'>Location</th>
                                    <th className='text-truncate align-middle'>USP</th>
                                    <th className='text-truncate align-middle'>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {groups.map((u, i) => {
                                    return <GroupBody
                                        sno={i + 1}
                                        name={u.name}
                                        location={u.location}
                                        usp={u.usp}
                                        key={i}
                                        onEdit={() => handleEdit(u)}
                                        onDelete={() => handleDelete(u)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </VerticalLayout>
    </>
}

const GroupBody = ({ sno, name, location, usp, onEdit, onDelete }: TableBody) => {
    return <tr>
        <td className='align-middle'>{sno}</td>
        <td className='align-middle'>{name}</td>
        <td className='text-capitalize align-middle'>{location}</td>
        <td className='text-capitalize align-middle'>{usp}</td>
        <td>
            <div className='hstack gap-1'>
                <button className='btn btn-sm btn-primary' onClick={onEdit}>Edit</button>
                <button className='btn btn-sm btn-danger' onClick={onDelete}>Delete</button>
            </div>
        </td>
    </tr>
}

export default Groups