import { MouseEventHandler, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import PageTitle from "../components/custom/components/PageTitle"
import VerticalLayout from "../components/custom/layouts/Vertical"
import MemberForm from "../components/MemberForm"
import Modal from "../components/Modal"
import { deleteMember, MemberType } from "../redux/actions/memberActions"
import { AppDispatch, StoreState } from "../redux/store"
import { FormType, FormTypeList } from "./Group"

interface TableBody {
    sno: number
    name: string
    gender: string
    dob: string
    fatherOrhusbandName: string
    aadhaarNo: string
    contactNo: string
    activityCategory: string
    primaryActivity: string
    groupId?: string
    groupName?: string
    role: string
    status: string
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
}

type HandleEdit = (value: MemberType) => void
type HandleDelete = (value: MemberType) => void

const Member = () => {
    const dispatch = useDispatch<AppDispatch>()

    const Members = useSelector<StoreState, Array<MemberType>>(state => (state.member))

    const [showForm, setShowForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<MemberType>()

    const handleAdd = () => {
        setShowForm(true)
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.EDIT)
        setEditData(value)
        setShowForm(true)
    }

    const handleDelete: HandleDelete = (value) => {
        if (value.id !== undefined) {
            dispatch(deleteMember(value.id)).then(text => {
                // toast(text)
            }).catch(text => {
                // toast(text, ToastTypes.ERROR)
            })
        }
    }

    const handleFormClose = () => {
        setShowForm(false)
        setFormType(FormTypeList.ADD)
        setEditData(undefined)
    }

    return <><Modal
        headerText={formType === FormTypeList.ADD ? 'Add Member' : 'Edit Member'}
        visible={showForm}
        onClose={handleFormClose}
        size='xl'
        centered
    >
        <MemberForm
            formType={formType}
            editData={editData}
            onSave={handleFormClose}
        />

    </Modal>

        <VerticalLayout>
            <PageTitle
                title="Member"
                buttonText="Add"
                onButtonClick={handleAdd}
            />

            <div className="card">
                <div className="card-body">
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Name</th>
                                    <th className='text-truncate align-middle'>Gender</th>
                                    <th className='text-truncate align-middle'>DOB</th>
                                    <th className='text-truncate align-middle'>Father/Husband Name</th>
                                    <th className='text-truncate align-middle'>Aadhaar Number</th>
                                    <th className='text-truncate align-middle'>Contact Number</th>
                                    <th className='text-truncate align-middle'>Activity Category</th>
                                    <th className='text-truncate align-middle'>Primary Activity</th>
                                    <th className='text-truncate align-middle'>Group</th>
                                    <th className='text-truncate align-middle'>Role</th>
                                    <th className='text-truncate align-middle'>Status</th>
                                    <th className='text-truncate align-middle'>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {Members.map((m, i) => {
                                    return <Memberbody
                                        sno={i + 1}
                                        name={m.name}
                                        aadhaarNo={m.aadhaarNo}
                                        activityCategory={m.activityCategory}
                                        contactNo={m.contactNo}
                                        dob={m.dob}
                                        fatherOrhusbandName={m.fatherOrhusbandName}
                                        gender={m.gender}
                                        primaryActivity={m.primaryActivity}
                                        role={m.role}
                                        status={m.status}
                                        groupId={m.groupId !== undefined ? m.groupId : ''}
                                        groupName={m.groupName !== undefined ? m.groupName : ''}
                                        key={i}
                                        onEdit={() => handleEdit(m)}
                                        onDelete={() => handleDelete(m)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </VerticalLayout>
    </>
}

const Memberbody = ({ sno, name, dob, fatherOrhusbandName, aadhaarNo, contactNo, activityCategory, primaryActivity, groupId, gender, role, status, groupName, onEdit, onDelete }: TableBody) => {
    return <tr>
        <td className='align-middle'>{sno}</td>
        <td className='align-middle'>{name}</td>
        <td className='text-capitalize align-middle'>{gender}</td>
        <td className='align-middle'>{dob}</td>
        <td className='text-capitalize align-middle'>{fatherOrhusbandName}</td>
        <td className='text-capitalize align-middle'>{aadhaarNo}</td>
        <td className='text-capitalize align-middle'>{contactNo}</td>
        <td className='text-capitalize align-middle'>{activityCategory}</td>
        <td className='text-capitalize align-middle'>{primaryActivity}</td>
        <td className='text-capitalize align-middle'>{groupName}</td>
        <td className='text-capitalize align-middle'>{role}</td>
        <td className='text-capitalize align-middle'>{status}</td>
        <td>
            <div className='hstack gap-1'>
                <button className='btn btn-sm btn-primary' onClick={onEdit}>Edit</button>
                <button className='btn btn-sm btn-danger' onClick={onDelete}>Delete</button>
            </div>
        </td>
    </tr>
}
export default Member