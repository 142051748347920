import { ShareManagementActionsList, ShareManagementSampleData, ShareManagementType } from "../actions/shareManagementActions";
import { Action } from "../actions/types";


export const shareManagementReducer = (state: ShareManagementType[] = ShareManagementSampleData, action: Action) => {

    switch (action.type) {

        case ShareManagementActionsList.ADD_SHARE_MANAGEMENT: {

            return [...state, action.data]
        }

        case ShareManagementActionsList.EDIT_SHARE_MANAGEMENT: {

            const index = state.findIndex(i => i.id === action.id)
            const newState = [...state]

            if (index > -1) {
                newState[index] = {
                    ...action.data,
                    id: action.id
                }
            }
            return newState
        }

        case ShareManagementActionsList.FETCH_SHARE_MANAGEMENT: {

            return state
        }

        case ShareManagementActionsList.DELETE_SHARE_MANAGEMENT: {
            return state.filter(i => i.id !== action.data.id)

        }

        default: return state
    }

}