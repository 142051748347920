import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import { activityManagementReducer } from "./reducers/activityManagementReducer";
import expenseReducer from "./reducers/expenseReducer";
import { groupReducer } from "./reducers/groupReducer";
import { memberReducer } from "./reducers/memberReducer";
import { productReducer } from "./reducers/productReducer";
import { purchaseReducer } from "./reducers/purchaseReducer";
import { SaleReducer } from "./reducers/salesReducer";
import { serviceBookingReducer } from "./reducers/serviceBookingReducer";
import { serviceReducer } from "./reducers/serviceReducer";
import { shareManagementReducer } from "./reducers/shareManagementReducer";
import { supplierReducer } from "./reducers/supplierReducer";
import { transactionReducer } from "./reducers/transactionReducer";


const rootReducer = combineReducers({
    group: groupReducer,
    member: memberReducer,
    service: serviceReducer,
    serviceBooking: serviceBookingReducer,
    expense: expenseReducer,
    transaction: transactionReducer,
    shareManagement: shareManagementReducer,
    activityManagement: activityManagementReducer,
    product: productReducer,
    sale: SaleReducer,
    supplier: supplierReducer,
    purchase: purchaseReducer,
})

const thunkMiddleWare = thunk

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
        const middlewares = getDefaultMiddleware()
        middlewares.push(thunkMiddleWare)
        return middlewares
    }

})

export type AppDispatch = typeof store.dispatch
export type StoreState = ReturnType<typeof rootReducer>

export default store