import { nanoid } from "@reduxjs/toolkit"
import { Dispatch } from "redux"

export enum ExpenseActionList {
    ADD_EXPENSE = 'ADD_EXPENSE',
    FETCH_EXPENSE = 'FETCH_EXPENSE',
    EDIT_EXPENSE = 'EDIT_EXPENSE',
    DELETE_EXPENSE = 'DELETE_EXPENSE',
}

export interface ExpenseType {

    id?: string
    title: string

}

export const ExpenseSampleData = [
    {
        id: '1',
        title: 'Stationaries'
    },
    {
        id: '2',
        title: 'Internet Bill'
    },
    {
        id: '3',
        title: 'EB Bill'
    },
    {
        id: '4',
        title: 'Telephone Bill'
    },
]

export interface DeleteExpense {
    id: string
}

export interface AddExpenseAction {
    type: ExpenseActionList.ADD_EXPENSE
    data: ExpenseType
}

export interface FetchExpenseAction {
    type: ExpenseActionList.FETCH_EXPENSE
    // data: Array<ExpenseType>
}

export interface UpdateExpenseAction {
    type: ExpenseActionList.EDIT_EXPENSE
    data: ExpenseType
    id: string
}

export interface DeleteExpenseAction {
    type: ExpenseActionList.DELETE_EXPENSE
    data: DeleteExpense

}

export type ExpenseActions = AddExpenseAction | FetchExpenseAction | UpdateExpenseAction | DeleteExpenseAction

export const addExpense = (data: ExpenseType) => {
    return async (dispatch: Dispatch) => {

        return dispatch<AddExpenseAction>({
            type: ExpenseActionList.ADD_EXPENSE,
            data: {
                ...data,
                id: nanoid(5)
            }
        })
    }
}

export const updateExpense = (data: ExpenseType, id: string) => {
    return async (dispatch: Dispatch) => {

        return dispatch<UpdateExpenseAction>({
            type: ExpenseActionList.EDIT_EXPENSE,
            data: data,
            id: id
        })
    }
}

export const fetchExpense = () => {
    return async (dispatch: Dispatch) => {

        return dispatch<FetchExpenseAction>({
            type: ExpenseActionList.FETCH_EXPENSE
            // data: response.data
        })
    }
}

export const deleteExpense = (id: string) => {
    return async (dispatch: Dispatch) => {

        return dispatch<DeleteExpenseAction>({
            type: ExpenseActionList.DELETE_EXPENSE,
            data: { id }
        })
    }
}

