import { MemberActionsList, MemberSampleData, MemberType } from "../actions/memberActions";
import { Action } from "../actions/types";


export const memberReducer = (state: MemberType[] = MemberSampleData, action: Action) => {

    switch (action.type) {

        case MemberActionsList.ADD_MEMBER: {

            return [...state, action.data]
        }

        case MemberActionsList.EDIT_MEMBER: {

            const index = state.findIndex(i => i.id === action.id)
            const newState = [...state]

            if (index > -1) {
                newState[index] = {
                    ...action.data,
                    id: action.id
                }
            }
            return newState
        }

        case MemberActionsList.FETCH_MEMBER: {

            return state
        }

        case MemberActionsList.DELETE_MEMBER: {
            return state.filter(i => i.id !== action.data.id)

        }

        default: return state
    }

}