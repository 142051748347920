import { ExpenseActionList, ExpenseSampleData, ExpenseType } from "../actions/expenseActions"
import { Action } from "../actions/types"


const expenseReducer = (state: Array<ExpenseType> = ExpenseSampleData, action: Action) => {
    switch (action.type) {

        case ExpenseActionList.ADD_EXPENSE:
            return [
                ...state,
                action.data,
            ]

        case ExpenseActionList.EDIT_EXPENSE:
            const updated = [...state].slice()
            const index = updated.findIndex(u => u.id === action.id)

            if (index > -1) {
                updated[index] = {
                    ...action.data,
                    id: action.id
                }
            }

            return updated

        case ExpenseActionList.FETCH_EXPENSE:
            return state

        case ExpenseActionList.DELETE_EXPENSE:
            return state.filter(s => s.id !== action.data.id)

        default:
            return state
    }
}

export default expenseReducer