import PageTitle from "../components/custom/components/PageTitle"
import VerticalLayout from "../components/custom/layouts/Vertical"
import Dashboard from "./Dashboard"

const Home = () => {

    return <VerticalLayout>
        <PageTitle
            title=""
        />
        <Dashboard />
    </VerticalLayout>
}
export default Home