import { nanoid } from "@reduxjs/toolkit"
import { Dispatch } from "redux"

export enum UnitDropDown {
    KG = 'KG',
    LTR = 'LTR',
    PIECE = 'PIECE',
    BAG_60KG = 'BAG - 60KG',
    BAG_50KG = 'BAG - 50KG',
    BAG_25KG = 'BAG - 25KG',
    BAG_10KG = 'BAG - 10KG',
    BAG_5KG = 'BAG - 5KG',
}
export type UnitTypes = UnitDropDown.BAG_10KG | UnitDropDown.BAG_25KG | UnitDropDown.BAG_50KG | UnitDropDown.BAG_5KG | UnitDropDown.BAG_60KG | UnitDropDown.KG | UnitDropDown.LTR | UnitDropDown.PIECE

export interface Product {
    id?: string
    name: string
    category: string
    unit: UnitTypes
    pricePerUnit: number

}

export const ProductSampleData = [
    {
        id: '1',
        category: 'Rice',
        name: 'Ponni Rice',
        unit: UnitDropDown.BAG_10KG,
        pricePerUnit: 750
    },
    {
        id: '2',
        category: 'Rice',
        name: 'Seeraga Samba Rice',
        unit: UnitDropDown.BAG_10KG,
        pricePerUnit: 200
    },
    {
        id: '3',
        category: 'Oil',
        name: 'Sesame Oil',
        unit: UnitDropDown.BAG_10KG,
        pricePerUnit: 200
    },
    {
        id: '4',
        category: 'Oil',
        name: 'Cocunut Oil',
        unit: UnitDropDown.BAG_10KG,
        pricePerUnit: 200
    },
    {
        id: '5',
        category: 'Oil',
        name: 'Groundnut Oil',
        unit: UnitDropDown.BAG_10KG,
        pricePerUnit: 200
    },
]

export enum ProductActionsList {
    ADD_PRODUCT = 'ADD_PRODUCT',
    EDIT_PRODUCT = 'EDIT_PRODUCT',
    FETCH_PRODUCT = 'FETCH_PRODUCT',
    DELETE_PRODUCT = 'DELETE_PRODUCT'
}

export interface DeleteProduct {
    id: string
}

export interface AddProductAction {
    type: ProductActionsList.ADD_PRODUCT
    data: Product
}

export interface EditProductAction {
    type: ProductActionsList.EDIT_PRODUCT
    data: Product
    id: string
}

export interface FetchProductAction {
    type: ProductActionsList.FETCH_PRODUCT
    // data: Product[]
}

export interface DeleteProductAction {
    type: ProductActionsList.DELETE_PRODUCT
    data: DeleteProduct
}

export type ProductActions = AddProductAction | EditProductAction | FetchProductAction | DeleteProductAction

export const addProduct = (data: Product) => {
    return async (dispatch: Dispatch) => {
        return dispatch<AddProductAction>({
            type: ProductActionsList.ADD_PRODUCT,
            data: {
                ...data,
                id: nanoid(5)
            }
        })
    }
}

export const editProduct = (data: Product, id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<EditProductAction>({
            type: ProductActionsList.EDIT_PRODUCT,
            data: data,
            id: id
        })
    }
}

export const fetchProduct = () => {
    return async (dispatch: Dispatch) => {
        return dispatch<FetchProductAction>({
            type: ProductActionsList.FETCH_PRODUCT
            // data: response.data
        })
    }
}

export const deleteProduct = (id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<DeleteProductAction>({
            type: ProductActionsList.DELETE_PRODUCT,
            data: { id }
        })
    }
}