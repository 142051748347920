import { nanoid } from "@reduxjs/toolkit"
import { Dispatch } from "redux"

export enum StatusTypes {
    NEW = 'New',
    PROGRESS = 'Progress',
    CANCELLED = 'Cancelled',
    COMPLETED = 'Completed',
}

export type ActivityStatus = StatusTypes.NEW | StatusTypes.CANCELLED | StatusTypes.PROGRESS | StatusTypes.COMPLETED

export interface ActivityManagementType {

    id?: string
    groupId?: string
    groupName?: string
    memberId?: string
    memberName?: string
    task: string
    status: ActivityStatus
    hoursSpent: number
    startDate: string
    endDate: string
    createTimestamp?: string
    createBy?: string
}

export const ActivityManagementSampleData = [
    {
        id: '1',
        groupId: '1',
        groupName: 'service',
        memberId: '1',
        memberName: 'Aishu',
        task: 'Task 1',
        status: StatusTypes.COMPLETED,
        hoursSpent: 2,
        startDate: '2022-12-03',
        endDate: '2022-12-05',
        createTimestamp: '1670461666',
        createBy: 'member',
    },
    {
        id: '2',
        groupId: '2',
        groupName: 'service',
        memberId: '2',
        memberName: 'Ram',
        task: 'Task 2',
        status: StatusTypes.COMPLETED,
        hoursSpent: 2,
        startDate: '2022-12-03',
        endDate: '2022-12-05',
        createTimestamp: '1670461666',
        createBy: 'member',
    }
]


export enum ActivityManagementActionsList {
    ADD_ACTIVITY_MANAGEMENT = 'ADD_ACTIVITY_MANAGEMENT',
    EDIT_ACTIVITY_MANAGEMENT = 'EDIT_ACTIVITY_MANAGEMENT',
    FETCH_ACTIVITY_MANAGEMENT = 'FETCH_ACTIVITY_MANAGEMENT',
    DELETE_ACTIVITY_MANAGEMENT = 'DELETE_ACTIVITY_MANAGEMENT'
}

export interface DeleteActivityManagement {
    id: string
}

export interface AddActivityManagementAction {
    type: ActivityManagementActionsList.ADD_ACTIVITY_MANAGEMENT
    data: ActivityManagementType
}

export interface EditActivityManagementAction {
    type: ActivityManagementActionsList.EDIT_ACTIVITY_MANAGEMENT
    data: ActivityManagementType
    id: string
}

export interface FetchActivityManagementAction {
    type: ActivityManagementActionsList.FETCH_ACTIVITY_MANAGEMENT
    // data: ActivityManagement[]
}

export interface DeleteActivityManagementAction {
    type: ActivityManagementActionsList.DELETE_ACTIVITY_MANAGEMENT
    data: DeleteActivityManagement
}

export type ActivityManagementActions = AddActivityManagementAction | EditActivityManagementAction | FetchActivityManagementAction | DeleteActivityManagementAction

export const addActivityManagement = (data: ActivityManagementType) => {
    return async (dispatch: Dispatch) => {
        return dispatch<AddActivityManagementAction>({
            type: ActivityManagementActionsList.ADD_ACTIVITY_MANAGEMENT,
            data: {
                ...data,
                id: nanoid(5)
            }
        })

    }
}

export const editActivityManagement = (data: ActivityManagementType, id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<EditActivityManagementAction>({
            type: ActivityManagementActionsList.EDIT_ACTIVITY_MANAGEMENT,
            data: data,
            id: id
        })

    }
}

export const fetchActivityManagement = () => {
    return async (dispatch: Dispatch) => {
        return dispatch<FetchActivityManagementAction>({
            type: ActivityManagementActionsList.FETCH_ACTIVITY_MANAGEMENT,
            // data: data
        })

    }
}

export const deleteActivityManagement = (id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<DeleteActivityManagementAction>({
            type: ActivityManagementActionsList.DELETE_ACTIVITY_MANAGEMENT,
            data: { id }
        })

    }
}