import { MouseEventHandler, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PageTitle from '../components/custom/components/PageTitle'
import VerticalLayout from '../components/custom/layouts/Vertical'
import ExpenseForm from '../components/ExpenseForm'
import Modal from '../components/Modal'
import { addKey } from '../config/functions'
import { deleteExpense, ExpenseType } from '../redux/actions/expenseActions'
import { Key } from '../redux/actions/types'
import { AppDispatch, StoreState } from '../redux/store'
import { FormType, FormTypeList } from './Group'

interface TableBody {
    sno: number
    title: string
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
}

type HandleEdit = (value: ExpenseType) => void
type HandleDelete = (value: ExpenseType) => void

const Expense = () => {
    // const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()

    const expenseList = useSelector<StoreState, Array<ExpenseType & Key>>(state => addKey(state.expense))
    const [showForm, setShowForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<ExpenseType>()

    const handleAdd = () => {
        setShowForm(true)
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.EDIT)
        setEditData(value)
        setShowForm(true)
    }

    const handleDelete: HandleDelete = (value) => {
        if (value.id !== undefined) {
            dispatch(deleteExpense(value.id)).then(text => {
                // toast(text)
            }).catch(text => {
                // toast(text, ToastTypes.ERROR)
            })
        }
    }

    const handleFormClose = () => {
        setShowForm(false)
        setFormType(FormTypeList.ADD)
        setEditData(undefined)
    }

    return <>
        <Modal
            headerText={formType === FormTypeList.ADD ? 'Add Expense ' : 'Edit Expense '}
            visible={showForm}
            onClose={handleFormClose}
            size='lg'
            centered
        >
            <ExpenseForm
                formType={formType}
                editData={editData}
                onSave={handleFormClose}
            />

        </Modal>
        <VerticalLayout>
            <PageTitle
                title='Expense '
                buttonText='ADD'
                onButtonClick={handleAdd}
            />
            <div className="card">
                <div className="card-body">
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Title</th>
                                    <th className='text-truncate align-middle'>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {expenseList.map((em, i) => {
                                    return <ExpenseBody
                                        sno={i + 1}
                                        title={em.title}
                                        key={em.key}
                                        onEdit={() => handleEdit(em)}
                                        onDelete={() => handleDelete(em)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </VerticalLayout>
    </>
}

const ExpenseBody = ({ sno, title, onEdit, onDelete }: TableBody) => {
    return <tr>
        <td className='align-middle'>{sno}</td>
        <td className='text-capitalize align-middle'>{title}</td>
        <td>
            <div className='hstack gap-1'>
                <button className='btn btn-sm btn-primary align-middle' onClick={onEdit}>Edit</button>
                <button className='btn btn-danger btn-sm' onClick={onDelete}>Delete</button>
            </div>
        </td>
    </tr>
}

export default Expense
