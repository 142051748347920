import { nanoid } from "@reduxjs/toolkit";
import { Dispatch } from "redux";

export interface Purchase {
    id?: string;
    productId?: string;
    productName?: string;
    unit: string;
    price: number;
    quantity: number;
    amount: number;
    memberId?: string;
    memberName?: string;
    date: string;

}

export const PurchaseSampleData = [
    {
        id: '1',
        productId: '1',
        productName: 'ponnirice BAG-50KG',
        unit: 'BAG-50KG',
        price: 750,
        quantity: 2,
        amount: 1500,
        memberId: '1',
        memberName: 'Aishu',
        date: '2022-12-20',
    },
    {
        id: '2',
        productId: '2',
        productName: 'Ponnirice BAG-5KG',
        unit: 'BAG-5KG',
        price: 200,
        quantity: 3,
        amount: 600,
        memberId: '2',
        memberName: 'Ram',
        date: '2022-12-12',
    }
]

export enum PurchaseActionsList {
    ADD_PURCHASE = 'ADD_PURCHASE',
    EDIT_PURCHASE = 'EDIT_PURCHASE',
    FETCH_PURCHASE = 'FETCH_PURCHASE',
    DELETE_PURCHASE = 'DELETE_PURCHASE'
}

export interface DeletePurchase {
    id: string
}

export interface AddPurchaseAction {
    type: PurchaseActionsList.ADD_PURCHASE
    data: Purchase
}

export interface EditPurchaseAction {
    type: PurchaseActionsList.EDIT_PURCHASE
    data: Purchase
    id: string
}

export interface FetchPurchaseAction {
    type: PurchaseActionsList.FETCH_PURCHASE

}

export interface DeletePurchaseAction {
    type: PurchaseActionsList.DELETE_PURCHASE
    data: DeletePurchase
}

export type PurchaseActions = AddPurchaseAction | EditPurchaseAction | FetchPurchaseAction | DeletePurchaseAction

export const addPurchase = (data: Purchase) => {
    return async (dispatch: Dispatch) => {
        return dispatch<AddPurchaseAction>({
            type: PurchaseActionsList.ADD_PURCHASE,
            data: {
                ...data,
                id: nanoid(5)
            }
        })

    }
}

export const editPurchase = (data: Purchase, id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<EditPurchaseAction>({
            type: PurchaseActionsList.EDIT_PURCHASE,
            data: data,
            id: id
        })

    }
}

export const fetchPurchase = () => {
    return async (dispatch: Dispatch) => {
        return dispatch<FetchPurchaseAction>({
            type: PurchaseActionsList.FETCH_PURCHASE,

        })

    }
}

export const deletePurchase = (id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<DeletePurchaseAction>({
            type: PurchaseActionsList.DELETE_PURCHASE,
            data: { id }
        })

    }
}
