import { MouseEventHandler, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import PageTitle from "../components/custom/components/PageTitle"
import VerticalLayout from "../components/custom/layouts/Vertical"
import Modal from "../components/Modal"
import ServiceForm from "../components/ServiceForm"
import { BillingTypes, deleteService, ServiceType, ServiceTypes, StatusTypes } from "../redux/actions/serviceActions"
import { AppDispatch, StoreState } from "../redux/store"
import { FormType, FormTypeList } from "./Group"

interface TableBody {
    sno: number
    type: ServiceTypes
    title: string
    billing: BillingTypes
    rate: number
    contactPerson: string
    contactNumber: string
    status: StatusTypes
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
}

type HandleEdit = (value: ServiceType) => void
type HandleDelete = (value: ServiceType) => void

const Service = () => {

    const dispatch = useDispatch<AppDispatch>()

    const services = useSelector<StoreState, Array<ServiceType>>(state => (state.service))

    const [showForm, setShowForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<ServiceType>()

    const handleAdd = () => {
        setShowForm(true)
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.EDIT)
        setEditData(value)
        setShowForm(true)
    }

    const handleDelete: HandleDelete = (value) => {
        if (value.id !== undefined) {
            dispatch(deleteService(value.id)).then(text => {
                // toast(text)
            }).catch(text => {
                // toast(text, ToastTypes.ERROR)
            })
        }
    }

    const handleFormClose = () => {
        setShowForm(false)
        setFormType(FormTypeList.ADD)
        setEditData(undefined)
    }

    return <><Modal
        headerText={formType === FormTypeList.ADD ? 'Add Service' : 'Edit Service'}
        visible={showForm}
        onClose={handleFormClose}
        size='xl'
        centered
    >
        <ServiceForm
            formType={formType}
            editData={editData}
            onSave={handleFormClose}
        />

    </Modal>
        <VerticalLayout>
            <PageTitle
                title="Service"
                buttonText="Add"
                onButtonClick={handleAdd}
            />

            <div className="card">
                <div className="card-body">
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Type</th>
                                    <th className='text-truncate align-middle'>Title</th>
                                    <th className='text-truncate align-middle'>Billing</th>
                                    <th className='text-truncate align-middle'>Rate</th>
                                    <th className='text-truncate align-middle'>Contact Person</th>
                                    <th className='text-truncate align-middle'>Contact Number</th>
                                    <th className='text-truncate align-middle'>status</th>
                                    <th className='text-truncate align-middle'>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {services.map((u, i) => {
                                    return <ServiceBody
                                        sno={i + 1}
                                        type={u.type}
                                        title={u.title}
                                        billing={u.billing}
                                        rate={u.rate}
                                        contactPerson={u.contactPerson}
                                        contactNumber={u.contactNumber}
                                        status={u.status}
                                        key={i}
                                        onEdit={() => handleEdit(u)}
                                        onDelete={() => handleDelete(u)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </VerticalLayout>
    </>
}

const ServiceBody = ({ sno, type, title, billing, rate, contactPerson, contactNumber, status, onEdit, onDelete }: TableBody) => {
    return <tr>
        <td className='align-middle'>{sno}</td>
        <td className='align-middle'>{type}</td>
        <td className='align-middle'>{title}</td>
        <td className='align-middle'>{billing}</td>
        <td className='align-middle'>{rate}</td>
        <td className='text-capitalize align-middle'>{contactPerson}</td>
        <td className='text-capitalize align-middle'>{contactNumber}</td>
        <td className='text-capitalize align-middle'>{status}</td>
        <td>
            <div className='hstack gap-1'>
                <button className='btn btn-sm btn-primary' onClick={onEdit}>Edit</button>
                <button className='btn btn-sm btn-danger' onClick={onDelete}>Delete</button>
            </div>
        </td>
    </tr>
}

export default Service