import { MouseEventHandler, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import PageTitle from "../components/custom/components/PageTitle"
import VerticalLayout from "../components/custom/layouts/Vertical"
import Modal from "../components/Modal"
import ServiceBookingForm from "../components/ServiceBookingForm"
import { BookingType, deleteServiceBooking } from "../redux/actions/serviceBookingActions"
import { AppDispatch, StoreState } from "../redux/store"
import { FormType, FormTypeList } from "./Group"

interface TableBody {
    sno: number
    serviceId?: string
    serviceName?: string
    customerName: string
    customerAddress: string
    contactNumber: string
    billing: string
    rate: number
    unit: number
    amount: number
    discount: number
    finalAmount: number
    date: string
    createTimestamp?: string
    createBy?: string
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
}

type HandleEdit = (value: BookingType) => void
type HandleDelete = (value: BookingType) => void

const ServiceBooking = () => {

    const dispatch = useDispatch<AppDispatch>()

    const Bookings = useSelector<StoreState, Array<BookingType>>(state => (state.serviceBooking))

    const [showForm, setShowForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<BookingType>()

    const handleAdd = () => {
        setShowForm(true)
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.EDIT)
        setEditData(value)
        setShowForm(true)
    }

    const handleDelete: HandleDelete = (value) => {
        if (value.id !== undefined) {
            dispatch(deleteServiceBooking(value.id)).then(text => {
                // toast(text)
            }).catch(text => {
                // toast(text, ToastTypes.ERROR)
            })
        }
    }

    const handleFormClose = () => {
        setShowForm(false)
        setFormType(FormTypeList.ADD)
        setEditData(undefined)
    }

    return <><Modal
        headerText={formType === FormTypeList.ADD ? 'Add Service Booking' : 'Edit Service Booking'}
        visible={showForm}
        onClose={handleFormClose}
        size='xl'
        centered
    >
        <ServiceBookingForm
            formType={formType}
            editData={editData}
            onSave={handleFormClose}
        />

    </Modal>
        <VerticalLayout>
            <PageTitle
                title="Service Booking"
                buttonText="Add"
                onButtonClick={handleAdd}
            />

            <div className="card">
                <div className="card-body">
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Date</th>
                                    <th className='text-truncate align-middle'>Service</th>
                                    <th className='text-truncate align-middle'>Customer Name</th>
                                    <th className='text-truncate align-middle'>Customer Address</th>
                                    <th className='text-truncate align-middle'>Contact Number</th>
                                    <th className='text-truncate align-middle'>Billing</th>
                                    <th className='text-truncate align-middle'>Rate</th>
                                    <th className='text-truncate align-middle'>Unit</th>
                                    <th className='text-truncate align-middle'>Amount</th>
                                    <th className='text-truncate align-middle'>Discount Amount</th>
                                    <th className='text-truncate align-middle'>Final Amount</th>
                                    <th className='text-truncate align-middle'>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {Bookings.map((u, i) => {
                                    return <ServiceBookingBody
                                        sno={i + 1}
                                        serviceId={u.serviceId}
                                        serviceName={u.serviceName}
                                        customerName={u.customerName}
                                        customerAddress={u.customerAddress}
                                        contactNumber={u.contactNumber}
                                        billing={u.billing}
                                        rate={u.rate}
                                        unit={u.unit}
                                        amount={u.amount}
                                        finalAmount={u.finalAmount}
                                        discount={u.discount}
                                        date={u.date}
                                        createBy={u.createBy}
                                        createTimestamp={u.createTimestamp}
                                        key={i}
                                        onEdit={() => handleEdit(u)}
                                        onDelete={() => handleDelete(u)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </VerticalLayout>
    </>
}

const ServiceBookingBody = ({ sno, amount, customerAddress, customerName, date, discount, finalAmount, unit, serviceName, billing, rate, createBy, contactNumber, createTimestamp, onEdit, onDelete }: TableBody) => {
    return <tr>
        <td className='align-middle'>{sno}</td>
        <td className='text-truncate align-middle'>{date}</td>
        <td className='align-middle'>{serviceName}</td>
        <td className='text-capitalize align-middle'>{customerName}</td>
        <td className='text-capitalize align-middle'>{customerAddress}</td>
        <td className='text-capitalize align-middle'>{contactNumber}</td>
        <td className='align-middle'>{billing}</td>
        <td className='align-middle'>{rate}</td>
        <td className='align-middle'>{unit}</td>
        <td className='align-middle'>{amount}</td>
        <td className='align-middle'>{discount}</td>
        <td className='align-middle'>{finalAmount}</td>
        <td>
            <div className='hstack gap-1'>
                <button className='btn btn-sm btn-primary' onClick={onEdit}>Edit</button>
                <button className='btn btn-sm btn-danger' onClick={onDelete}>Delete</button>
            </div>
        </td>
    </tr>
}

export default ServiceBooking