import { ChangeEventHandler, MouseEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addKey } from "../config/functions"
import { FormType, FormTypeList } from "../pages/Group"
import { Group } from "../redux/actions/groupActions"
import { ActivityCategories, ActivityCategoryTypes, addMember, editMember, fetchMember, MemberType } from "../redux/actions/memberActions"
import { AppDispatch, StoreState } from "../redux/store"
import { FormInput } from "./custom/components"
import Option from "./Option"
import { ToastTypes, useToast } from "./toast/Toastprovider"

interface Props {
    formType: FormType
    editData?: MemberType
    onSave: () => void
}

const AGRI_INPUT_TYPES = addKey([
    {
        text: 'Seeds',
        value: 'Seeds'
    },
    {
        text: 'Fertlizer',
        value: 'Fertlizer'
    },
    {
        text: 'Pest',
        value: 'Pest'
    },
    {
        text: 'Medicines',
        value: 'Medicines'
    },

])

const FIELD_ACTIVITY_TYPES = addKey([
    {
        text: 'Soil test',
        value: 'Soil test'
    },
    {
        text: 'Water test',
        value: 'Water test'
    },
    {
        text: 'Plough',
        value: 'Plough'
    },
    {
        text: 'Sow',
        value: 'Sow'
    },
    {
        text: 'Weed',
        value: 'Weed'
    },
    {
        text: 'Pest control',
        value: 'Pest control'
    },
    {
        text: 'Growth montoring',
        value: 'Growth montoring'
    },
    {
        text: 'Intervention',
        value: 'Intervention'
    },
    {
        text: 'Harvest',
        value: 'Harvest'
    },
])

const AGRI_OUTPUT_TYPES = addKey([
    {
        text: 'Drying',
        value: 'Drying'
    },
    {
        text: 'Packing',
        value: 'Packing'
    },
    {
        text: 'Bidding',
        value: 'Bidding'
    },
    {
        text: 'Value addition',
        value: 'Value addition'
    },
    {
        text: 'Sales & Marketing',
        value: 'Sales & Marketing'
    },
])

const MemberForm = ({ formType, onSave, editData }: Props) => {
    // const routeMaster = useSelector<StoreState, Array<RouteMasterType & Key>>(state => addKey(state.routeMaster))

    const toast = useToast()
    const groups = useSelector<StoreState, Array<Group>>(state => (state.group))


    const dispatch = useDispatch<AppDispatch>()

    const [name, setName] = useState<string>('')
    const [nameError, setNameError] = useState<string>('')
    const [dob, setDob] = useState<string>('')
    const [dobError, setDobError] = useState<string>('')
    const [gender, setGender] = useState<string>('')
    const [genderError, setGenderError] = useState<string>('')
    const [fatherOrHusbandName, setFatherOrHusbandName] = useState<string>('')
    const [fatherOrHusbandNameError, setFatherOrHusbandNameError] = useState<string>('')
    const [aadhaarNo, setAadhaarNo] = useState<string>('')
    const [aadhaarNoError, setAadhaarNoError] = useState<string>('')
    const [contactNo, setContactNo] = useState<string>('')
    const [contactNoError, setContactNoError] = useState<string>('')
    const [activityCategory, setActivityCategory] = useState<ActivityCategories>(ActivityCategoryTypes.AGRI_INPUT)
    const [activityCategoryError, setActivityCategoryError] = useState<string>('')
    const [primaryActivity, setPrimaryActivity] = useState<string>('')
    const [primaryActivityError, setPrimaryActivityError] = useState<string>('')
    const [role, setRole] = useState<string>('')
    const [roleError, setRoleError] = useState<string>('')
    const [status, setStatus] = useState<string>('')
    const [statusError, setStatusError] = useState<string>('')
    const [groupId, setGroupId] = useState<string>('')
    const [groupIdError, setGroupIdError] = useState<string>('')
    const [groupName, setGroupName] = useState<string>('')

    const resetStates = () => {
        setRole('')
        setRoleError('')
        setGender('')
        setGenderError('')
        setStatus('')
        setStatusError('')
        setName('')
        setNameError('')
        setDob('')
        setDobError('')
        setFatherOrHusbandName('')
        setFatherOrHusbandNameError('')
        setAadhaarNo('')
        setAadhaarNoError('')
        setContactNo('')
        setContactNoError('')
        setActivityCategory(ActivityCategoryTypes.AGRI_INPUT)
        setActivityCategoryError('')
        setPrimaryActivity('')
        setPrimaryActivityError('')
    }

    const handleRole: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRole(e.target.value)
        setRoleError('')
    }

    const handleStatus: ChangeEventHandler<HTMLInputElement> = (e) => {
        setStatus(e.target.value)
        setStatusError('')
    }

    const handleGender: ChangeEventHandler<HTMLInputElement> = (e) => {
        setGender(e.target.value)
        setGenderError('')
    }

    const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setName(e.target.value)
        setNameError('')
    }

    const handleDob: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDob(e.target.value)
        setDobError('')
    }

    const handleFatherOrHusbandName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setFatherOrHusbandName(e.target.value)
        setFatherOrHusbandNameError('')
    }

    const handleAadhaarNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAadhaarNo(e.target.value)
        setAadhaarNoError('')
    }

    const handleContactNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        setContactNo(e.target.value)
        setContactNoError('')
    }

    const handleActivityCategory: ChangeEventHandler<HTMLInputElement> = (e) => {
        setActivityCategory(e.target.value as ActivityCategories)
        setActivityCategoryError('')
    }

    const handlePrimaryActivity: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPrimaryActivity(e.target.value)
        setPrimaryActivityError('')
    }

    const handleGroupId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setGroupId(e.target.value)
        const index = groups.findIndex(g => g.id !== undefined && g.id.toString() === e.target.value)

        if (index > -1) {
            setGroupName(groups[index].name)
        }
        setGroupIdError('')
    }


    const handleClear: MouseEventHandler<HTMLButtonElement> = (e) => {
        resetStates()
    }

    const handleSave = () => {
        let error = false
        const data: MemberType = {
            name: name,
            gender: gender,
            dob: dob,
            fatherOrhusbandName: fatherOrHusbandName,
            aadhaarNo: aadhaarNo,
            contactNo: contactNo,
            activityCategory: activityCategory,
            primaryActivity: primaryActivity,
            groupId: groupId,
            groupName: groupName,
            role: role,
            status: status
        }

        if (data.name === "") {
            setNameError('Name required')
            error = true
        } else if ((data.name).length > 30) {
            setNameError('Name should be within 30 characters')
            error = true
        }

        if (data.dob === "") {
            setDobError('DOB required')
            error = true
        }

        if (data.gender === "") {
            setGenderError('Gender required')
            error = true
        }

        if (data.fatherOrhusbandName === "") {
            setFatherOrHusbandNameError('Father/HusbandName required')
            error = true
        } else if ((data.fatherOrhusbandName).length > 30) {
            setFatherOrHusbandNameError('Father/HusbandName should be within 30 characters')
            error = true
        }

        if (data.aadhaarNo === "") {
            setAadhaarNoError("Aadhaar.No required")
            error = true
        }

        if (data.contactNo === "") {
            setContactNoError('Contact.No required')
            error = true
        }

        if ([ActivityCategoryTypes.AGRI_INPUT, ActivityCategoryTypes.AGRI_OUTPUT, ActivityCategoryTypes.FIELD_ACTIVITY].indexOf(data.activityCategory as ActivityCategories) === -1) {
            setActivityCategoryError('Activity Category must be Agri Input/Agri Output/Field Activity')
            error = true
        }

        if (data.primaryActivity === "") {
            setPrimaryActivityError('Primary Activity required')
            error = true
        }

        if (data.role === "") {
            setRoleError('Role required')
            error = true
        }

        if (data.status === "") {
            setStatusError('Status required')
            error = true
        }


        if (!error) {
            if (formType === FormTypeList.ADD) {

                dispatch(addMember(data)).then(text => {
                    // toast(text)
                    onSave()
                    dispatch(fetchMember())
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.EDIT && editData !== undefined && editData.id !== undefined) {
                dispatch(editMember(data, editData.id)).then(text => {
                    // toast(text)
                    onSave()
                    dispatch(fetchMember())
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }

    useEffect(() => {
        if (formType === FormTypeList.EDIT && editData !== undefined) {

            setName(editData.name)
            setDob(editData.dob)
            setGender(editData.gender)
            setFatherOrHusbandName(editData.fatherOrhusbandName)
            setAadhaarNo(editData.aadhaarNo.toString())
            setContactNo(editData.contactNo)
            setActivityCategory(editData.activityCategory)
            setPrimaryActivity(editData.primaryActivity)
            setRole(editData.role !== undefined ? editData.role : '')
            setStatus(editData.status)
            setGroupId(editData.groupId !== undefined ? editData.groupId.toString() : '')
        } else {
            resetStates()
        }
    }, [formType, editData])


    return <div className='container'>
        <div className="row">
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Name'
                    labelClassName="required"
                    value={name}
                    onChange={handleName}
                    placeholder='Name'
                    errorText={nameError}
                    containerClass="mb-2"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Gender'
                    labelClassName="required"
                    value={gender}
                    onChange={handleGender}
                    placeholder='Gender'
                    errorText={genderError}
                    containerClass="mb-2"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Dob'
                    labelClassName="required"
                    value={dob}
                    onChange={handleDob}
                    placeholder='Dob'
                    errorText={dobError}
                    containerClass="mb-2"
                    type="date"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='FatherOrHusbandName'
                    labelClassName="required"
                    value={fatherOrHusbandName}
                    onChange={handleFatherOrHusbandName}
                    placeholder='FatherOrHusbandName'
                    errorText={fatherOrHusbandNameError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Aadhaar No'
                    labelClassName="required"
                    value={aadhaarNo}
                    onChange={handleAadhaarNo}
                    placeholder='Aadhaar No'
                    errorText={aadhaarNoError}
                    type="number"
                    containerClass="mb-2"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Contact No'
                    labelClassName="required"
                    value={contactNo}
                    onChange={handleContactNo}
                    placeholder='Contact No'
                    errorText={contactNoError}
                    type="number"
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Activity Category'
                    labelClassName="required"
                    value={activityCategory}
                    onChange={handleActivityCategory}
                    placeholder='Activity Category'
                    errorText={activityCategoryError}
                    type="select"
                    containerClass="mb-2"
                >
                    <option value="">Select</option>
                    <option value="Agri input">Agri Input</option>
                    <option value="Field activity">Field Activity</option>
                    <option value="Agri output">Agri Output</option>

                </FormInput>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Primary Activity'
                    labelClassName="required"
                    value={primaryActivity}
                    onChange={handlePrimaryActivity}
                    placeholder='Primary Activity'
                    errorText={primaryActivityError}
                    containerClass="mb-2"
                    type="select"
                >
                    <option value="">Select</option>
                    {activityCategory === 'Agri input' &&
                        AGRI_INPUT_TYPES.map((ai, i) => {
                            return <Option value={ai.value} key={i}>{ai.text}</Option>
                        })
                    }
                    {activityCategory === 'Field activity' &&
                        FIELD_ACTIVITY_TYPES.map((fa, i) => {
                            return <Option value={fa.value} key={i}>{fa.text}</Option>
                        })
                    }
                    {activityCategory === 'Agri output' &&
                        AGRI_OUTPUT_TYPES.map((ao, i) => {
                            return <Option value={ao.value} key={i}>{ao.text}</Option>
                        })
                    }
                </FormInput>
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Group'
                    labelClassName="required"
                    value={groupId}
                    onChange={handleGroupId}
                    placeholder='Group'
                    errorText={groupIdError}
                    containerClass="mb-2"
                    type="select"
                >
                    <option value="">Select</option>
                    {groups.map((g, i) => {
                        return <Option value={g.id} key={i}>{g.name}</Option>
                    })}
                </FormInput>
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Role'
                    labelClassName="required"
                    value={role}
                    onChange={handleRole}
                    placeholder='Role'
                    errorText={roleError}
                    containerClass="mb-2"
                    type="select"
                >
                    <option value="">Select</option>
                    <option value="director">Director</option>
                    <option value="accountant">Accountant</option>
                    <option value="member">Member</option>

                </FormInput>
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Status'
                    labelClassName="required"
                    value={status}
                    onChange={handleStatus}
                    placeholder='Status'
                    errorText={statusError}
                    type="select"
                    containerClass="mb-2"
                >
                    <option value="">Select</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>

                </FormInput>
            </div>
        </div>
        <div className="d-flex justify-content-end hstack gap-1">
            <button className="btn btn-primary" onClick={handleClear}>Clear</button>
            <button className="btn btn-success" onClick={handleSave}>Save</button>
        </div>

    </div>
}
export default MemberForm