/* eslint-disable no-throw-literal */
import { nanoid } from "@reduxjs/toolkit"
import { Dispatch } from "redux"

export enum TransactionActionList {
    ADD_TRANSACTION = 'ADD_TRANSACTION',
    FETCH_TRANSACTION = 'FETCH_TRANSACTION',
    EDIT_TRANSACTION = 'EDIT_TRANSACTION',
    DELETE_TRANSACTION = 'DELETE_TRANSACTION',
}

export enum VoucherTypes {
    RECEIPT = 'Receipt',
    PAYMENT = 'Payment',
}

export enum PaymentModes {
    CASH = 'Cash',
    BANK = 'Bank',
}

export enum TransactionTypes {
    BUS = 'Bus',
    GENERAL = 'General',
}

export type TransactionVoucherTypes = VoucherTypes.PAYMENT | VoucherTypes.RECEIPT
export type TransactionPaymentModes = PaymentModes.CASH | PaymentModes.BANK
export type TransactionForTypes = TransactionTypes.BUS | TransactionTypes.GENERAL

export interface TransactionType {

    id?: string
    number?: string
    orderNumber?: number
    financialYear: string
    branch: number
    voucherType: TransactionVoucherTypes
    paymentMode: TransactionPaymentModes
    transactionFor?: TransactionForTypes
    // routeId?: number
    source?: string
    sourceId?: number
    // accountGroupName: string
    accountNumber: string
    accountName: string
    accountType: string
    date: string
    particulars: string
    partyAccount?: string
    partyAccountHead?: string
    details?: string
    debitAmount: string
    creditAmount: string
    balanceAmount?: string
    refNo?: string
    openingBalance?: string //for monthly report only
    createdBy?: string
    createTimestamp?: string
    bankNumber?: string
    bankName?: string

}

export const TransactionSampleData = [
    {
        id: '1',
        number: '1',
        orderNumber: 1,
        financialYear: "2022-2023",
        branch: 1,
        voucherType: VoucherTypes.PAYMENT,
        paymentMode: PaymentModes.CASH,
        transactionFor: TransactionTypes.BUS,
        // routeId: 100001,
        source: "Transaction",
        sourceId: 0,
        accountGroupName: "route",
        accountNumber: '900003',
        accountName: "Ks Promoters",
        accountType: "main",
        debitAmount: '200',
        creditAmount: '0',
        date: "2022-12-25",
        particulars: "Ticket Printing",
        refNo: "",
        createTimestamp: "Oct 18, 2022",
        createdBy: "admin",
        partyAccount: '900001'
    },
    {
        id: '2',
        number: '1',
        orderNumber: 2,
        financialYear: "2022-2023",
        branch: 1,
        voucherType: VoucherTypes.PAYMENT,
        paymentMode: PaymentModes.CASH,
        transactionFor: TransactionTypes.BUS,
        routeId: 100001,
        source: "Transaction",
        sourceId: 0,
        accountGroupName: "accounting",
        accountNumber: '900001',
        accountName: "Cash Account",
        accountType: "main",
        debitAmount: '0',
        creditAmount: '200',
        date: "2022-12-25",
        particulars: "Ticket Printing",
        refNo: "",
        createTimestamp: "Oct 18, 2022",
        createdBy: "admin",
        partyAccount: '900003'

    }
]

export type DeleteTransaction = {
    number: string
}

export interface AddTransactionAction {
    type: TransactionActionList.ADD_TRANSACTION
    data: TransactionType
}

export interface FetchTransactionAction {
    type: TransactionActionList.FETCH_TRANSACTION
    // data: Array<TransactionType>
}

export interface UpdateTransactionAction {
    type: TransactionActionList.EDIT_TRANSACTION
    data: TransactionType
    id: string
}

export interface DeleteTransactionAction {
    type: TransactionActionList.DELETE_TRANSACTION
    data: DeleteTransaction
}

export type TransactionActions = AddTransactionAction | FetchTransactionAction | UpdateTransactionAction | DeleteTransactionAction

export const addTransaction = (data: TransactionType) => {
    return async (dispatch: Dispatch) => {

        return dispatch<AddTransactionAction>({
            type: TransactionActionList.ADD_TRANSACTION,
            data: {
                ...data,
                id: nanoid(5)
            }
        })
    }
}

export const updateTransaction = (data: TransactionType, id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<UpdateTransactionAction>({
            type: TransactionActionList.EDIT_TRANSACTION,
            data: data,
            id: id
        })
    }
}

export const fetchTransaction = () => {
    return async (dispatch: Dispatch) => {
        return dispatch<FetchTransactionAction>({
            type: TransactionActionList.FETCH_TRANSACTION
            // data: response.data
        })
    }
}

export const deleteTransaction = (number: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<DeleteTransactionAction>({
            type: TransactionActionList.DELETE_TRANSACTION,
            data: { number }
        })
    }
}

