import { ChangeEventHandler, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { FormType, FormTypeList } from "../pages/Group"
import { addProduct, editProduct, Product, UnitDropDown, UnitTypes } from "../redux/actions/productActions"
import { AppDispatch } from "../redux/store"
import { FormInput } from "./custom/components"
import Option from "./Option"
import { ToastTypes, useToast } from "./toast/Toastprovider"

const UNIT_DROPS = [
    {
        text: 'KG',
        value: 'KG'
    },
    {
        text: 'LTR',
        value: 'LTR'
    },
    {
        text: 'PIECE',
        value: 'PIECE'
    }, {
        text: 'BAG 60KG',
        value: 'BAG - 60KG'
    }, {
        text: 'BAG 50KG',
        value: 'BAG - 50KG'
    }, {
        text: 'BAG 25KG',
        value: 'BAG - 25KG'
    }, {
        text: 'BAG 10KG',
        value: 'BAG - 10KG'
    }, {
        text: 'BAG 5KG',
        value: 'BAG - 5KG'
    },
]

interface Props {
    formType: FormType
    editData?: Product
    onSave: () => void
}

const ProductForm = ({ formType, onSave, editData }: Props) => {
    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()
    

    const [name, setName] = useState<string>('')
    const [nameError, setNameError] = useState<string>('')
    const [category, setCategory] = useState<string>('')
    const [categoryError, setCategoryError] = useState<string>('')
    const [unit, setUnit] = useState<UnitTypes>(UnitDropDown.KG)
    const [unitError, setUnitError] = useState<string>('')
    const [pricePerUnit, setPricePerUnit] = useState<string>('')
    const [pricePerUnitError, setPricePerUnitError] = useState<string>('')



    const resetStates = () => {
        setName('')
        setNameError('')
        setCategory('')
        setCategoryError('')
        setUnit(UnitDropDown.KG)
        setUnitError('')
        setPricePerUnit('')
        setPricePerUnitError('')

    }

    const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setName(e.target.value)
        setNameError('')
    }

    const handleCategory: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCategory(e.target.value)
        setCategoryError('')
    }

    const handleUnit: ChangeEventHandler<HTMLInputElement> = (e) => {
        setUnit(e.target.value as UnitTypes)
        setUnitError('')
    }

    const handlePricePerUnit: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPricePerUnit(e.target.value)
        setPricePerUnitError('')
    }

    const handleClear = () => {
        resetStates()
    }

    const handleSave = () => {
        let error = false
        const data: Product = {
            name: name,
            unit: unit,
            category: category,
            pricePerUnit: parseInt(pricePerUnit)
        }

        if (data.name === '') {
            setNameError('Name required')
            error = true
        } else if ((data.name).length > 15) {
            setNameError('Name should be within 15 letters')
            error = true
        }

        if ([UnitDropDown.BAG_10KG, UnitDropDown.BAG_25KG, UnitDropDown.BAG_50KG, UnitDropDown.BAG_5KG, UnitDropDown.BAG_60KG, UnitDropDown.KG, UnitDropDown.LTR, UnitDropDown.PIECE].indexOf(data.unit as UnitTypes) === -1) {
            setUnitError('Unit must be KG/LTR/PIECE/BAG-5KG/BAG-10KG/BAG-25KG/BAG-50KG/BAG-60KG')
            error = true
        }

        if (data.category === '') {
            setCategoryError('Category required')
            error = true
        } else if ((data.category).length > 15) {
            setCategoryError('Category should be within 15 letters')
            error = true
        }
        if (isNaN(data.pricePerUnit)) {
            setPricePerUnitError('Price Per Unit required')
            error = true
        } else if ((data.pricePerUnit) < 0) {
            setPricePerUnitError('Invalid Price Per Unit')
            error = true
        }

        if (!error) {
            if (formType === FormTypeList.ADD) {

                dispatch(addProduct(data)).then(text => {
                    // toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.EDIT && editData !== undefined && editData.id !== undefined) {
                dispatch(editProduct(data, editData.id)).then(text => {
                    // toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }

    useEffect(() => {
        if (formType === FormTypeList.EDIT && editData !== undefined) {
            setCategory(editData.category)
            setUnit(editData.unit as UnitTypes)
            setName(editData.name)
            setPricePerUnit(editData.pricePerUnit.toString())
        } else {
            resetStates()
        }
    }, [formType, editData])


    return <div className='container'>
        <div className="row">
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Category'
                    labelClassName="required"
                    value={category}
                    onChange={handleCategory}
                    placeholder='Category'
                    errorText={categoryError}
                    containerClass="mb-2"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Name'
                    labelClassName="required"
                    value={name}
                    onChange={handleName}
                    placeholder='Name'
                    errorText={nameError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Unit'
                    labelClassName="required"
                    value={unit}
                    onChange={handleUnit}
                    placeholder='Unit'
                    errorText={unitError}
                    containerClass="mb-2"
                    type="select"
                >
                    <option value=''>Select</option>
                    {UNIT_DROPS.map(ud => {
                        return <Option value={ud.value}>{ud.text}</Option>
                    })}
                </FormInput>

            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Price Per Unit'
                    labelClassName="required"
                    value={pricePerUnit}
                    onChange={handlePricePerUnit}
                    placeholder='Price Per Unit'
                    errorText={pricePerUnitError}
                    containerClass="mb-2"
                    type="number"
                />
            </div>

        </div>

        <div className="d-flex justify-content-end hstack gap-1 mt-2">
            <button className="btn btn-primary" onClick={handleClear}>Clear</button>
            <button className="btn btn-success" onClick={handleSave}>Save</button>
        </div>

    </div >
}
export default ProductForm