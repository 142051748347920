import { nanoid } from "@reduxjs/toolkit"
import { Dispatch } from "redux"

export interface Group {
    id?: string
    name: string
    location: string
    usp: string
}

export const GroupSampleData = [
    {
        id: '1',
        name: 'service',
        location: 'chennai',
        usp: 'usp'
    },
    {
        id: '2',
        name: 'service',
        location: 'Trichy',
        usp: 'usp'
    },
]

export enum GroupActionsList {
    ADD_GROUP = 'ADD_GROUP',
    EDIT_GROUP = 'EDIT_GROUP',
    FETCH_GROUP = 'FETCH_GROUP',
    DELETE_GROUP = 'DELETE_GROUP'
}

export interface DeleteGroup {
    id: string
}

export interface AddGroupAction {
    type: GroupActionsList.ADD_GROUP
    data: Group
}

export interface EditGroupAction {
    type: GroupActionsList.EDIT_GROUP
    data: Group
    id: string
}

export interface FetchGroupAction {
    type: GroupActionsList.FETCH_GROUP
    // data: Group[]
}

export interface DeleteGroupAction {
    type: GroupActionsList.DELETE_GROUP
    data: DeleteGroup
}

export type GroupActions = AddGroupAction | EditGroupAction | FetchGroupAction | DeleteGroupAction

export const addGroup = (data: Group) => {
    return async (dispatch: Dispatch) => {
        return dispatch<AddGroupAction>({
            type: GroupActionsList.ADD_GROUP,
            data: {
                ...data,
                id: nanoid(5)
            }
        })
    }
}

export const editGroup = (data: Group, id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<EditGroupAction>({
            type: GroupActionsList.EDIT_GROUP,
            data: data,
            id: id
        })
    }
}

export const fetchGroup = () => {
    return async (dispatch: Dispatch) => {
        return dispatch<FetchGroupAction>({
            type: GroupActionsList.FETCH_GROUP
            // data: response.data
        })
    }
}

export const deleteGroup = (id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<DeleteGroupAction>({
            type: GroupActionsList.DELETE_GROUP,
            data: { id }
        })
    }
}