import { ChangeEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FormType, FormTypeList } from "../pages/Group"
import { addSale, editSale, Sale } from "../redux/actions/salesActions"
import { AppDispatch, StoreState } from "../redux/store"
import { FormInput } from "./custom/components"
import { ToastTypes, useToast } from "./toast/Toastprovider"
import { Product } from "../redux/actions/productActions"
import Option from "./Option"
import { UnitDropDown, UnitTypes } from "../redux/actions/productActions"



interface Props {
    formType: FormType
    editData?: Sale
    onSave: () => void
}

const SaleForm = ({ formType, onSave, editData }: Props) => {
    const toast = useToast()
    const products = useSelector<StoreState, Array<Product>>(state => (state.product))

    const dispatch = useDispatch<AppDispatch>()

    const [productId, setProductId] = useState<string>('')
    const [productIdError, setProductIdError] = useState<string>('')
    const [productName, setProductName] = useState<string>('')
    const [price, setPrice] = useState<string>('')
    const [priceError, setPriceError] = useState<string>('')
    const [unit, setUnit] = useState<UnitTypes>(UnitDropDown.KG)
    const [unitError, setUnitError] = useState<string>('')
    const [quantity, setQuantity] = useState<string>('')
    const [quantityError, setQuantityError] = useState<string>('')
    const [amount, setAmount] = useState<string>('')
    const [amountError, setAmountError] = useState<string>('')
    const [customerName, setCustomerName] = useState<string>('')
    const [customerNameError, setCustomerNameError] = useState<string>('')
    const [customerAddress, setCustomerAddress] = useState<string>('')
    const [customerAddressError, setCustomerAddressError] = useState<string>('')
    const [contactNumber, setContactNumber] = useState<string>('')
    const [contactNumberError, setContactNumberError] = useState<string>('')
    const [date, setDate] = useState<string>('')
    const [dateError, setDateError] = useState<string>('')



    const resetStates = () => {
        setProductId('')
        setProductIdError('')
        setProductName('')
        setProductIdError('')
        setPrice('')
        setPriceError('')
        setUnit(UnitDropDown.KG)
        setUnitError('')
        setQuantity('')
        setQuantityError('')
        setAmount('')
        setAmountError('')
        setCustomerName('')
        setCustomerNameError('')
        setCustomerAddress('')
        setCustomerAddressError('')
        setContactNumber('')
        setContactNumberError('')
        setDate('')
        setDateError('')


    }

    const handleProductId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setProductId(e.target.value)
        const index = products.findIndex(p => p.id !== undefined && p.id === e.target.value)

        if (index > -1) {
            setProductName(products[index].name)
            setUnit(products[index].unit)
            setPrice((products[index].pricePerUnit).toString())
        }
        setProductIdError('')
        setUnitError('')
        setPriceError('')
    }
    const handlePrice: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPrice(e.target.value)
        setPriceError('')
    }
    const handleUnit: ChangeEventHandler<HTMLInputElement> = (e) => {
        setUnit(e.target.value as UnitTypes)
        setUnitError('')
    }
    const handleAmount: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAmount(e.target.value)
        setAmountError('')
    }
    const handleQuantity: ChangeEventHandler<HTMLInputElement> = (e) => {
        setQuantity(e.target.value)
        setQuantityError('')
        setAmountError('')

    }
    const handleCustomerName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCustomerName(e.target.value)
        setCustomerNameError('')
    }
    const handleCustomerAddress: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCustomerAddress(e.target.value)
        setCustomerAddressError('')
    }
    const handleContactNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setContactNumber(e.target.value)
        setContactNumberError('')
    }
    const handleDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDate(e.target.value)
        setDateError('')
    }

    const handleClear = () => {
        resetStates()
    }

    const handleSave = () => {
        let error = false

        const data: Sale = {
            productId: productId,
            price: parseInt(price),
            unit: unit,
            quantity: parseInt(quantity),
            amount: parseInt(amount),
            customerName: customerName,
            customerAddress: customerAddress,
            contactNumber: contactNumber,
            date: date,
            productName: productName,
        }

        if (data.productName === '') {
            setProductIdError('Product required')
            error = true
        } else if ((data.productName).length > 15) {
            setProductIdError('Invalid Product')
            error = true
        }
        if (isNaN(data.price)) {
            setPriceError('Price required')
            error = true
        }
        else if ((data.price) > 15) {
            setPriceError('Invalid Price')
            error = true
        }

        if ([UnitDropDown.BAG_10KG, UnitDropDown.BAG_25KG, UnitDropDown.BAG_50KG, UnitDropDown.BAG_5KG, UnitDropDown.BAG_60KG, UnitDropDown.KG, UnitDropDown.LTR, UnitDropDown.PIECE].indexOf(data.unit as UnitTypes) === -1) {
            setUnitError('Unit required')
            error = true
        }

        if (isNaN(data.quantity)) {
            setQuantityError('Quantity required')
            error = true
        } else if ((data.quantity) < 0) {
            setQuantityError('Invalid Quantity')
            error = true
        }


        if (isNaN(data.amount)) {
            setAmountError('Amount required')
            error = true
        } else if ((data.amount) > 15) {
            setAmountError('Invalid Amount')
            error = true
        }

        if (data.customerName === '') {
            setCustomerNameError('Customer Name required')
            error = true
        } else if ((data.customerName).length > 15) {
            setCustomerNameError('Invalid Customer Name')
            error = true
        }

        if (data.customerAddress === '') {
            setCustomerAddressError('Customer Address required')
            error = true
        } else if ((data.customerAddress).length > 15) {
            setCustomerAddressError('Invalid Customer Address')
            error = true
        }

        if (data.contactNumber === '') {
            setContactNumberError('Contact Number required')
            error = true
        } else if ((data.contactNumber).length > 15) {
            setContactNumberError('Invalid Contact Number')
            error = true
        }

        if (data.date === '') {
            setDateError('Date required')
            error = true
        } else if ((data.date).length > 15) {
            setDateError('Invalid Date')
            error = true
        }


        if (!error) {
            if (formType === FormTypeList.ADD) {

                dispatch(addSale(data)).then(text => {
                    // toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.EDIT && editData !== undefined && editData.id !== undefined) {
                dispatch(editSale(data, editData.id)).then(text => {
                    // toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }

    useEffect(() => {
        if (formType === FormTypeList.EDIT && editData !== undefined) {
            setProductId(editData.productId !== undefined ? editData.productId.toString() : '')
            setPrice(editData.price.toString())
            setUnit(editData.unit)
            setQuantity(editData.quantity.toString())
            setAmount(editData.amount.toString())
            setCustomerName(editData.customerName)
            setCustomerAddress(editData.customerAddress)
            setContactNumber(editData.contactNumber)
            setDate(editData.date)
            setProductName(editData.productName !== undefined ? editData.productName.toString() : '')

        } else {
            resetStates()
        }
    }, [formType, editData])


    useEffect(() => {
        if (price !== "" && quantity !== "") {
            let totalAmount = (parseInt(price) * parseInt(quantity))
            setAmount(totalAmount.toString())
        }
    }, [price, quantity])


    return <div className='container'>
        <div className="row">

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Product'
                    labelClassName="required"
                    value={productId}
                    onChange={handleProductId}
                    placeholder='Product'
                    errorText={productIdError}
                    containerClass="mb-2"
                    type="select"
                >
                    <option value="">Select</option>
                    {products.map((p, i) => {
                        return <Option value={p.id} key={i}>{p.name}</Option>
                    })}
                </FormInput>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Price'
                    labelClassName="required"
                    value={price}
                    onChange={handlePrice}
                    placeholder='Price'
                    errorText={priceError}
                    containerClass="mb-2"
                    disabled
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Unit'
                    labelClassName="required"
                    value={unit}
                    onChange={handleUnit}
                    placeholder='Unit'
                    errorText={unitError}
                    containerClass="mb-2"
                    disabled
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Quantity'
                    labelClassName="required"
                    value={quantity}
                    onChange={handleQuantity}
                    placeholder='Quantity'
                    errorText={quantityError}
                    containerClass="mb-2"
                    type="number"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Amount'
                    labelClassName="required"
                    value={amount}
                    onChange={handleAmount}
                    placeholder='Amount'
                    errorText={amountError}
                    containerClass="mb-2"
                    type="number"
                    disabled

                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Customer Name'
                    labelClassName="required"
                    value={customerName}
                    onChange={handleCustomerName}
                    placeholder='Customer Name'
                    errorText={customerNameError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Customer Address'
                    labelClassName="required"
                    value={customerAddress}
                    onChange={handleCustomerAddress}
                    placeholder='CustomerAddress'
                    errorText={customerAddressError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Contact Number'
                    labelClassName="required"
                    value={contactNumber}
                    onChange={handleContactNumber}
                    placeholder='Contact Number'
                    errorText={contactNumberError}
                    containerClass="mb-2"
                    type="number"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Date'
                    labelClassName="required"
                    value={date}
                    onChange={handleDate}
                    placeholder='Date'
                    errorText={dateError}
                    containerClass="mb-2"
                    type="date"
                />
            </div>
        </div>

        <div className="d-flex justify-content-end hstack gap-1 mt-2">
            <button className="btn btn-primary" onClick={handleClear}>Clear</button>
            <button className="btn btn-success" onClick={handleSave}>Save</button>
        </div>

    </div >
}
export default SaleForm;