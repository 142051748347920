import { BrowserRouter, Route, Routes } from "react-router-dom"
import ActivityManagement from "../pages/ActivityManagement"
import AddTransactionForm from "../pages/AddTransaction"
import Expense from "../pages/Expense"
import Group from "../pages/Group"
import Home from "../pages/Home"
import Member from "../pages/Member"
import Products from "../pages/Product"
import PurchaseView from "../pages/Purchase"
import Sales from "../pages/Sales"
import Service from "../pages/Service"
import ServiceBooking from "../pages/ServiceBooking"
import ShareManagement from "../pages/ShareManagement"
import Supplier from "../pages/Supplier"

const Router = () => {

    return <>
        <BrowserRouter>
            <Routes>
                <Route path='/' index element={<Home />} />
                <Route path='/group' element={<Group />} />
                <Route path='/member' element={<Member />} />
                <Route path='/service' element={<Service />} />
                <Route path='/booking' element={<ServiceBooking />} />
                <Route path='/expense' element={<Expense />} />
                <Route path='/add_transaction' element={<AddTransactionForm />} />
                <Route path='/share_management' element={<ShareManagement />} />
                <Route path='/activity_management' element={<ActivityManagement />} />
                <Route path='/product' element={<Products />} />
                <Route path='/sales' element={<Sales />} />
                <Route path='/supplier_management' element={<Supplier />} />
                <Route path='/purchase' element={<PurchaseView />} />

            </Routes>
        </BrowserRouter>
    </>
}
export default Router