import { nanoid } from "@reduxjs/toolkit"
import { Dispatch } from "redux"

export enum ServiceTypesList {
    EQUIPMENT = 'Equipment',
    SERVICE = 'Service',
}
export type ServiceTypes = ServiceTypesList.EQUIPMENT | ServiceTypesList.SERVICE

export enum BillingTypesList {
    HOURLY = 'Hourly',
    DAILY = 'Daily',
}

export type BillingTypes = BillingTypesList.DAILY | BillingTypesList.HOURLY

export enum StatusList {
    BOOKED = 'Booked',
    AVAILABLE = 'Available',
    DISABLED = 'Disabled',
}

export type StatusTypes = StatusList.AVAILABLE | StatusList.BOOKED | StatusList.DISABLED

export interface ServiceType {
    id?: string
    type: ServiceTypes
    title: string
    billing: BillingTypes
    rate: number
    contactPerson: string
    contactNumber: string
    status: StatusTypes
}

export const ServiceSampleData = [
    {
        id: '1',
        type: ServiceTypesList.EQUIPMENT,
        title: 'Tractor',
        billing: BillingTypesList.HOURLY,
        rate: 250,
        contactPerson: 'Kumar',
        contactNumber: '7864576343',
        status: StatusList.AVAILABLE,
    },
    {
        id: '2',
        type: ServiceTypesList.SERVICE,
        title: 'Human service',
        billing: BillingTypesList.DAILY,
        rate: 500,
        contactPerson: 'Swaminathan',
        contactNumber: '7867696343',
        status: StatusList.AVAILABLE,
    }
]


export enum ServiceActionsList {
    ADD_SERVICE = 'ADD_SERVICE',
    EDIT_SERVICE = 'EDIT_SERVICE',
    FETCH_SERVICE = 'FETCH_SERVICE',
    DELETE_SERVICE = 'DELETE_SERVICE'
}

export interface DeleteService {
    id: string
}

export interface AddServiceAction {
    type: ServiceActionsList.ADD_SERVICE
    data: ServiceType
}

export interface EditServiceAction {
    type: ServiceActionsList.EDIT_SERVICE
    data: ServiceType
    id: string
}

export interface FetchServiceAction {
    type: ServiceActionsList.FETCH_SERVICE
    // data: Service[]
}

export interface DeleteServiceAction {
    type: ServiceActionsList.DELETE_SERVICE
    data: DeleteService
}

export type ServiceActions = AddServiceAction | EditServiceAction | FetchServiceAction | DeleteServiceAction

export const addService = (data: ServiceType) => {
    return async (dispatch: Dispatch) => {
        return dispatch<AddServiceAction>({
            type: ServiceActionsList.ADD_SERVICE,
            data: {
                ...data,
                id: nanoid(5)
            }
        })

    }
}

export const editService = (data: ServiceType, id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<EditServiceAction>({
            type: ServiceActionsList.EDIT_SERVICE,
            data: data,
            id: id
        })

    }
}

export const fetchService = () => {
    return async (dispatch: Dispatch) => {
        return dispatch<FetchServiceAction>({
            type: ServiceActionsList.FETCH_SERVICE,
            // data: data
        })
    }
}

export const deleteService = (id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<DeleteServiceAction>({
            type: ServiceActionsList.DELETE_SERVICE,
            data: { id }
        })

    }
}