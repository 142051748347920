import { nanoid } from "@reduxjs/toolkit"
import { Dispatch } from "redux"

export interface Supplier {
    id?: string
    code:string
    name:string	
    address:string	
    contactPerson:string	
    mobileNumber:string	
    gstNumber?:string	
    aadhaarNumber?:string	
    panNumber?:string	
    oldBalance?:number	
    oldBalanceDate?:string
}

export const SupplierSampleData = [
    {
        id: '1',
        code:"2344",	
        name:"Cath",	
        address:"Koradacheri",	
        contactPerson:"Catharin",
        mobileNumber:"9876543210",
        gstNumber:"123457689012345",
        aadhaarNumber:"123546789012",
        panNumber:'2134567890',
        oldBalance:200,	
        oldBalanceDate:"2022-12-12", 
    },
    {
        id: '2',
        code:"2345",	
        name:"Merlin",	
        address:"Thiruvarur",	
        contactPerson:"Francis",
        mobileNumber:"9876543210",
        gstNumber:"234156789012345",
        aadhaarNumber:"123546789012",
        panNumber:"2134568790",
        oldBalance:200,	
        oldBalanceDate:"2022-12-12", 
    },
]

export enum SupplierActionsList {
    ADD_SUPPLIER = 'ADD_SUPPLIER',
    EDIT_SUPPLIER = 'EDIT_SUPPLIER',
    FETCH_SUPPLIER = 'FETCH_SUPPLIER',
    DELETE_SUPPLIER = 'DELETE_SUPPLIER'
}

export interface DeleteSupplier {
    id: string
}

export interface AddSupplierAction {
    type: SupplierActionsList.ADD_SUPPLIER
    data: Supplier
}

export interface EditSupplierAction {
    type: SupplierActionsList.EDIT_SUPPLIER
    data: Supplier
    id: string
}

export interface FetchSupplierAction {
    type: SupplierActionsList.FETCH_SUPPLIER
    // data: Supplier[]
}

export interface DeleteSupplierAction {
    type: SupplierActionsList.DELETE_SUPPLIER
    data: DeleteSupplier
}

export type SupplierActions = AddSupplierAction | EditSupplierAction | FetchSupplierAction | DeleteSupplierAction

export const addSupplier = (data: Supplier) => {
    return async (dispatch: Dispatch) => {
        return dispatch<AddSupplierAction>({
            type: SupplierActionsList.ADD_SUPPLIER,
            data: {
                ...data,
                id: nanoid(5)
            }
        })
    }
}

export const editSupplier = (data: Supplier, id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<EditSupplierAction>({
            type: SupplierActionsList.EDIT_SUPPLIER,
            data: data,
            id: id
        })
    }
}

export const fetchSupplier = () => {
    return async (dispatch: Dispatch) => {
        return dispatch<FetchSupplierAction>({
            type: SupplierActionsList.FETCH_SUPPLIER
            // data: response.data
        })
    }
}

export const deleteSupplier = (id: string) => {
    return async (dispatch: Dispatch) => {
        return dispatch<DeleteSupplierAction>({
            type: SupplierActionsList.DELETE_SUPPLIER,
            data: { id }
        })
    }
}