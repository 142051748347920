import { MouseEventHandler } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface PageTitleProps {
    isLink?: boolean
    buttonText?: string;
    onButtonClick?: MouseEventHandler<HTMLButtonElement>
    title?: string;
    to?: string
}

/**
 * PageTitle
 */
const PageTitle = (props: PageTitleProps) => {

    return (
        <Row>
            <Col>
                <div className="page-title-box">
                    {props.buttonText && <div className="page-title-right">
                        {props.isLink && props.to && props.to !== ''
                            ? <Link to={props.to} className='btn btn-success'>{props.buttonText}</Link>
                            : <button className='btn btn-success' onClick={props.onButtonClick}>{props.buttonText}</button>}
                    </div>}
                    <h4 className="page-title">{props['title']}</h4>
                </div>
            </Col>
        </Row>
    );
};

export default PageTitle;
