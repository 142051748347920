import BarChart from "../components/BarChart"
import LineChart from "../components/LineChart"

const Dashboard = () => {
    return <>
        <div className="row mt-2">
            <h4>Purchase</h4>
            <div className="card">
                <div className="card-body">
                    <div className='w-100' style={{ height: '400px' }}>
                        <BarChart
                            data1={[100, 120, 200, 150, 90, 20]}
                            data2={[80, 100, 160, 120, 250, 15]}
                            data3={[150, 300, 220, 400, 80, 50]}
                            data4={[20, 170, 180, 200, 0, 15]}
                            data5={[250, 450, 150, 80, 100, 32]}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className="row mt-2">
            <h4>Sales</h4>
            <div className="card">
                <div className="card-body">
                    <div className='w-100' style={{ height: '400px' }}>
                        <BarChart
                            data1={[470, 520, 670, 580, 90, 50]}
                            data2={[580, 90, 500, 470, 520, 67]}
                            data3={[450, 520, 670, 580, 90, 50]}
                            data4={[100, 120, 200, 150, 90, 20]}
                            data5={[320, 600, 550, 100, 420, 50]}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className="row mt-2">
            <h4>Service</h4>
            <div className="card">
                <div className="card-body">
                    <div className='w-100' style={{ height: '400px' }}>
                        <LineChart />
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default Dashboard